"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventEmitter = void 0;
class EventEmitter {
    addEventListener(event, callback) {
        this.target.addEventListener(event, callback);
    }
    dispatchEvent(event, detail) {
        this.target.dispatchEvent(new CustomEvent(event, { detail }));
    }
    // Wait until event is fired
    async wait(event) {
        return new Promise(resolve => this.target.addEventListener(event, resolve, {
            // For simplicity, we will assume passive listeners.
            // Feel free to expose this as a configuration option.
            passive: true,
            // It is important to only trigger this listener once
            // so that we don't leak too many listeners.
            once: true,
        }));
    }
    // internal events object
    target = new EventTarget();
}
exports.EventEmitter = EventEmitter;
