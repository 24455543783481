import * as THREE from "three";
import { Component } from "wobble-engine/engine/core/Component";
import { SphereCollider } from "wobble-engine/engine/systems/physics/SphereCollider";
import { Rigidbody } from "wobble-engine/engine/systems/physics/Rigidbody";
import { MeshRenderer } from "wobble-engine/engine/systems/mesh/MeshRenderer";
import { IGrabbable } from "../interfaces/IGrabbable";
import { MathUtility } from "wobble-engine/engine/utility/MathUtility";
import gsap from "gsap";
import { WobbleShaderMaterial } from "wobble-engine/engine/shaders/wobble/WobbleShaderMaterial";
import { ISocket } from "../interfaces/ISocket";
import { EditorUtility } from "wobble-engine/engine/utility/EditorUtility";
import { EventEmitter } from "wobble-engine/engine/systems/events/EventEmitter";
// Class representing the actor in the scene
export class LightSocket extends Component {
    constructor(grabber, rope) {
        super();
        this.events = new EventEmitter();
        this.socket = null;
        this.rigidbody = null;
        this.grabbable = null;
        this.collider = null;
        this.meshRenderer = null;
        this.tween = null;
        this.pullTime = 0.0;
        this.grabber = null;
        this.rope = null;
        this.grabber = grabber;
        this.rope = rope;
    }
    // Setup the lightbulb gameObject for usage
    setup() {
        this.meshRenderer = this.gameObject.getComponent(MeshRenderer);
        this.meshRenderer.material = new WobbleShaderMaterial(new THREE.Color(0xffffff));
        this.meshRenderer.receiveShadows = true;
        this.meshRenderer.castShadows = true;
        this.collider = this.gameObject.addComponent(new SphereCollider(1, new THREE.Vector3(0, 0.75, 0)));
        this.rigidbody = this.gameObject.addComponent(new Rigidbody());
        this.grabbable = this.gameObject.addComponent(new IGrabbable());
        this.rigidbody.isKinematic = true;
        this.socket = this.gameObject.addComponent(new ISocket());
        this.socket.socketOffset.y = 0.5;
        this.socket.socketLayerID = "socket";
        this.rigidbody.recalculate();
        this.grabbable.events.addEventListener("OnGrab", (event) => {
            this.pullTime = 0.0;
            if (this.tween != null)
                this.tween.kill();
        });
        this.grabbable.events.addEventListener("OnDrag", (event) => {
        });
        this.grabbable.events.addEventListener("OnDrop", (event) => {
            this.tween = gsap.to(this.gameObject.transform.position, { duration: 3.0, ease: "elastic.out", 'x': 0.0, 'z': 0.0 });
            gsap.to(this.gameObject.transform.position, { duration: 0.2, 'y': 5.0 });
        });
        this.grabbable.maintainDistanceToAnchor = true;
        this.grabbable.anchorPoint = this.rope.start;
        this.grabbable.maxDistance = this.rope.length - 0.15;
        var folder = EditorUtility.addInspectors(this.gameObject, false);
        // ISocketInspector.create(this.socket, folder);
    }
    awake() {
        super.awake();
        this.setup();
    }
    update(dt) {
        super.update(dt);
        var targetPosition = this.rope.start;
        // get vector to target
        var vecToTarget = targetPosition.clone().sub(this.gameObject.transform.global_position);
        vecToTarget = vecToTarget.normalize();
        this.gameObject.transform.rotation.setFromQuaternion(MathUtility.lookRotation(this.gameObject.transform.forward, vecToTarget.multiplyScalar(-1.0)));
        // grabbable
        if (this.grabbable.isHeld) {
            var vecToTarget = targetPosition.clone().sub(this.gameObject.transform.global_position);
            var distance = vecToTarget.length();
            if (distance >= this.grabbable.maxDistance - 0.1)
                this.pullTime += dt * 0.001;
            else
                this.pullTime = 0.0;
            if (this.pullTime > 0.5) {
                // unplug
                if (this.socket.currentPlug != null) {
                    var grabbable = this.socket.currentPlug.gameObject.getComponent(IGrabbable);
                    this.grabber.drop();
                    this.socket.currentPlug.remove();
                    this.grabber.grab(grabbable);
                }
            }
        }
    }
}
