"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkinnedMeshRenderer = void 0;
const MeshRenderer_1 = require("./MeshRenderer");
const three_1 = require("three");
const Application_1 = require("../../core/Application");
/**
 * Attach this component to a gameobject to allow rendering of geometry
 * with a specified material.
 */
class SkinnedMeshRenderer extends MeshRenderer_1.MeshRenderer {
    /**
     * Construct this component with geometry and a material
     * @param geometry The geometry to render
     * @param material The material to use
     */
    constructor(skinnedMesh) {
        super(skinnedMesh);
        //console.log(skinnedMesh.boneTransform);
    }
    /**
     * Initialise's this component with the passed in gameobject
     * @param gameObject The gameObject that owns this component
     */
    initialise(gameObject) {
        super.initialise(gameObject);
        console.log(this._mesh.skeleton);
        (this._mesh.add(this._mesh.skeleton.bones[0]));
        var helper = new three_1.SkeletonHelper(this._mesh);
        Application_1.Application.activeScene.threejs_scene.add(helper);
    }
}
exports.SkinnedMeshRenderer = SkinnedMeshRenderer;
