"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Inspector = void 0;
const Editor_1 = require("./Editor");
class Inspector {
    get enabled() { return this.folder.visible; }
    set enabled(value) {
        if (value == true)
            this.enable();
        else
            this.disable;
    }
    ;
    constructor(title, parent = null, expanded = false) {
        if (parent == null)
            this.folder = Editor_1.EDITOR.pane.addFolder({ title });
        else
            this.folder = parent.folder.addFolder({ title });
        this.folder.expanded = expanded;
        this.disable();
    }
    enable() {
        this.folder.hidden = false;
    }
    disable() {
        this.folder.hidden = true;
    }
    refresh() {
        window.requestAnimationFrame(this.refresh.bind(this));
    }
    folder = null;
}
exports.Inspector = Inspector;
