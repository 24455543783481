"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = exports._Input = void 0;
const THREE = __importStar(require("three"));
const Application_1 = require("../../core/Application");
class _Input {
    // Public
    events = new EventTarget();
    // viewport position of the mouse spanning from -0.5 to 0.5
    get mouseViewportPosition() {
        return new THREE.Vector2(this.mousePosition.x / Application_1.Application.canvasDimensions.x * 2 - 1, -(this.mousePosition.y / Application_1.Application.canvasDimensions.y) * 2 + 1);
    }
    get mousePosition() {
        return this._mousePosition;
    }
    constructor() {
        document.addEventListener('pointerdown', (event) => {
            // discard repeat events
            if (Application_1.Application.elapsedTime - this.pointerDownTime < this.repeatEventThreshold
                && event.clientX == this._mousePosition.x && event.clientY == this.mousePosition.y)
                return;
            this._mousePosition = new THREE.Vector2(event.clientX, event.clientY);
            let detail = {
                mousePosition: new THREE.Vector2(event.clientX, event.clientY)
            };
            this.pointerDownTime = Application_1.Application.elapsedTime;
            this.events.dispatchEvent(new CustomEvent('OnPointerDown', { detail, bubbles: false }));
        });
        document.addEventListener('pointermove', (event) => {
            // discard repeat events
            if (Application_1.Application.elapsedTime - this.pointerMoveTime < this.repeatEventThreshold
                && event.clientX == this._mousePosition.x && event.clientY == this.mousePosition.y)
                return;
            this._mousePosition = new THREE.Vector2(event.clientX, event.clientY);
            let detail = {
                mousePosition: this._mousePosition,
                event: event
            };
            this.pointerMoveTime = Application_1.Application.elapsedTime;
            this.events.dispatchEvent(new CustomEvent('OnPointerMove', { detail }));
        });
        document.addEventListener('pointerup', (event) => {
            // discard repeat events
            if (Application_1.Application.elapsedTime - this.pointerUpTime < this.repeatEventThreshold
                && event.clientX == this._mousePosition.x && event.clientY == this.mousePosition.y)
                return;
            let detail = {
                mousePosition: new THREE.Vector2(event.clientX, event.clientY)
            };
            this.pointerUpTime = Application_1.Application.elapsedTime;
            this.events.dispatchEvent(new CustomEvent('OnPointerUp', { detail }));
        });
    }
    // Internal
    _mousePosition = new THREE.Vector2();
    pointerDownTime = 0.0;
    pointerMoveTime = 0.0;
    pointerUpTime = 0.0;
    repeatEventThreshold = 0.0001;
}
exports._Input = _Input;
exports.Input = new _Input();
