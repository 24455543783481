import * as THREE from "three";
import { Component } from "wobble-engine/engine/core/Component";
import { EventEmitter } from "wobble-engine/engine/systems/events/EventEmitter";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
// Class representing a visual rope
export class Rope extends Component {
    constructor() {
        super();
        this.events = new EventEmitter();
        this.start_position = new THREE.Vector3(-1, 0, 0);
        this.end_position = new THREE.Vector3(1, 0, 0);
        this.rope_length = 5;
        this.segments = 3;
        this.points = [];
        this.material = null;
        this.geometry = null;
        this.mesh = null;
        this.amplitude = 0.5;
        this.elapsed = 0.0;
    }
    get start() { return this.start_position; }
    set start(value) { this.start_position = value; }
    get end() { return this.end_position; }
    set end(value) { this.end_position = value; }
    get length() { return this.rope_length; }
    set length(value) { this.rope_length = value; }
    // Setup the switch gameObject for usage
    setup() {
        // create a line material
        this.material = new LineMaterial({ color: 0xffffff,
            wireframe: false,
            linewidth: 0.005 });
        this.geometry = new LineGeometry();
        this.points = this.distributePoints();
        this.geometry.setPositions(this.getPositions(this.points));
        this.mesh = new Line2(this.geometry, this.material);
        this.gameObject.threejs_object3d.add(this.mesh);
    }
    awake() {
        super.awake();
        this.setup();
    }
    update(dt) {
        this.points = this.distributePoints();
        this.geometry.setPositions(this.getPositions(this.points));
    }
    distributePoints() {
        let new_points = [];
        // evenly space the point segments along the length of the rope
        let vecBetween = this.end_position.clone().sub(this.start_position);
        let distance = Math.min(this.length, vecBetween.length());
        let segment_width = distance / (this.segments - 1);
        let offset = this.length - distance;
        vecBetween = vecBetween.normalize();
        for (let i = 0; i < this.segments; i++) {
            let position = new THREE.Vector3(0, 0, 0);
            position.add(this.start_position.clone().add(vecBetween.clone().multiplyScalar(segment_width * i)));
            let vertical_offset = Math.sin((i / (this.segments - 1)) * Math.PI) * offset * this.amplitude;
            position.y -= vertical_offset;
            new_points.push(position);
        }
        return new_points;
    }
    getPositions(points) {
        const positions = [];
        const spline = new THREE.CatmullRomCurve3(points);
        const divisions = Math.round(32 * points.length);
        const point = new THREE.Vector3();
        for (let i = 0, l = divisions; i < l; i++) {
            const t = i / l;
            spline.getPoint(t, point);
            positions.push(point.x, point.y, point.z);
        }
        return positions;
    }
}
