var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as THREE from "three";
import gsap from "gsap";
import { Component } from "wobble-engine/engine/core/Component";
import { Rigidbody } from "wobble-engine/engine/systems/physics/Rigidbody";
import { MeshRenderer } from "wobble-engine/engine/systems/mesh/MeshRenderer";
import { IToggleable } from "../interfaces/IToggleable";
import { EventEmitter } from "wobble-engine/engine/systems/events/EventEmitter";
import { BoxCollider } from "wobble-engine/engine/systems/physics/BoxCollider";
import { EditorUtility } from "wobble-engine/engine/utility/EditorUtility";
// Class representing the actor in the scene
export class Switch extends Component {
    constructor() {
        super();
        this.events = new EventEmitter();
        this.toggleable = null;
        this.duration = 0.25;
        this.meshRenderer = null;
    }
    // Is the light switch on or off?
    get IsOn() { return this.toggleable.IsOn; }
    // Set the lightswitch to on or off. note that
    set IsOn(value) {
        if (this.toggleable.IsOn != value)
            this.toggleable.toggle();
    }
    // Duration of toggle animation
    get Duration() { return this.duration; }
    set Duration(value) { this.duration = value; }
    // Setup the switch gameObject for usage
    setup() {
        // setup mesh renderer
        this.meshRenderer = this.gameObject.getComponent(MeshRenderer);
        // setup physics
        var collider = this.gameObject.addComponent(new BoxCollider(new THREE.Vector3(1, 1, 1), new THREE.Vector3(0.0, 0.0, 0.0)));
        var rigidbody = this.gameObject.addComponent(new Rigidbody());
        rigidbody.isKinematic = true;
        // setup api and interaction
        this.toggleable.events.addEventListener("OnToggle", (event) => {
            var isOn = event.detail.state;
            this.handleToggle(isOn);
        });
        EditorUtility.addInspectors(this.gameObject);
    }
    awake() {
        super.awake();
        // add required components
        this.toggleable = this.gameObject.addComponent(new IToggleable());
        this.setup();
    }
    update(dt) {
        super.update(dt);
    }
    // animate to the on or off state
    animate(on) {
        return __awaiter(this, void 0, void 0, function* () {
            if (on)
                yield gsap.to(this.gameObject.transform.rotation, { duration: this.Duration, x: 0.5 });
            else
                yield gsap.to(this.gameObject.transform.rotation, { duration: this.Duration, x: -0.5 });
        });
    }
    handleToggle(on) {
        this.animate(on).
            then(() => {
            let detail = { isOn: this.IsOn };
            this.events.dispatchEvent('OnToggle', { isOn: this.IsOn });
        });
    }
}
