"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rigidbody = void 0;
const Component_1 = require("../../core/Component");
const Physics_1 = require("./Physics");
const CANNON = __importStar(require("cannon-es"));
const Collider_1 = require("./Collider");
const THREE = __importStar(require("three"));
class Rigidbody extends Component_1.Component {
    get mass() { return this.cannon_body.mass; }
    set mass(value) { this.cannon_body.mass = value; }
    get disableSimulation() { return this._disableSimulation; }
    ;
    set disableSimulation(value) {
        if (value) {
            this.syncToBody();
            Physics_1.Physics.removeBody(this.cannon_body);
        }
        else {
            this.syncToTransform();
            Physics_1.Physics.addBody(this.cannon_body);
        }
        this._disableSimulation = value;
        this.recalculate();
    }
    get isKinematic() { return this.cannon_body.type == CANNON.BODY_TYPES.KINEMATIC; }
    set isKinematic(value) {
        if (value)
            this.cannon_body.type = CANNON.BODY_TYPES.KINEMATIC;
        else
            this.cannon_body.type = CANNON.BODY_TYPES.DYNAMIC;
        this.recalculate();
    }
    events = new EventTarget();
    /** @internal */
    constructor() {
        super();
    }
    ;
    containsObject(object) {
        return this.recursiveSearch(object.id, this.gameObject.threejs_object3d);
    }
    awake() {
        let world_position = this.gameObject.transform.global_position;
        this.cannon_body = new CANNON.Body({ mass: 1, position: new CANNON.Vec3(world_position.x, world_position.y, world_position.z) });
        this.isKinematic = false;
        this.refreshChildColliders();
        // listen for changes to the colliders
        this.gameObject.events.addEventListener('OnChildAdded', (event) => {
            this.refreshChildColliders();
        });
        this.gameObject.events.addEventListener('OnChildRemoved', (event) => {
            this.refreshChildColliders();
        });
        this.gameObject.events.addEventListener('OnParentChange', (event) => {
            this.refreshChildColliders();
        });
        Physics_1.Physics.addBody(this.cannon_body);
        this.cannon_body.addEventListener('collide', (event) => {
            this.onCollisionEnter(event);
        });
    }
    update(deltaTime) {
        if (!this.enabled)
            return;
        if (!this.isKinematic && !this.disableSimulation) {
            this.syncToBody();
        }
        else {
            this.syncToTransform();
            this.cannon_body.velocity.copy(new CANNON.Vec3());
            this.cannon_body.angularVelocity.copy(new CANNON.Vec3());
        }
    }
    destroy() {
    }
    /**
     * Does this rigidbody wrap the specified CANNON body?
     * @param body
     */
    containsBody(body) {
        return this.cannon_body.id == body.id;
    }
    /**
     * Refresh the rigidbody objects after a major transform change.
     */
    recalculate() {
        this.refreshChildColliders();
    }
    syncToBody() {
        this.gameObject.transform.global_position = new THREE.Vector3(this.cannon_body.position.x, this.cannon_body.position.y, this.cannon_body.position.z);
        this.gameObject.transform.global_quaternion = new THREE.Quaternion(this.cannon_body.quaternion.x, this.cannon_body.quaternion.y, this.cannon_body.quaternion.z, this.cannon_body.quaternion.w);
    }
    syncToTransform() {
        let world_pos = this.gameObject.transform.global_position;
        let world_quaternion = this.gameObject.transform.global_quaternion;
        this.cannon_body.position.set(world_pos.x, world_pos.y, world_pos.z);
        this.cannon_body.quaternion.set(world_quaternion.x, world_quaternion.y, world_quaternion.z, world_quaternion.w);
    }
    onCollisionEnter(event) {
        let detail = { relativeVelocity: event.contact.getImpactVelocityAlongNormal() };
        this.events.dispatchEvent(new CustomEvent('OnCollisionEnter', { detail }));
    }
    refreshChildColliders() {
        // sync to transform first
        this.syncToTransform();
        // remove existing colliders
        for (let shape of this.cannon_body.shapes)
            this.cannon_body.removeShape(shape);
        this.colliders = [];
        let components = [];
        this.gameObject.getComponentsInChildren(Collider_1.Collider, components);
        if (components.length == 0)
            console.log("[ERROR] Rigidbody should not be added to a gameobject without a collider");
        for (let component of components) {
            let collider = component;
            this.colliders.push(collider);
            collider.rigidbody = this;
            let cannon_shape = null;
            var rotation = new CANNON.Quaternion(this.gameObject.transform.quaternion.x, this.gameObject.transform.quaternion.y, this.gameObject.transform.quaternion.z, this.gameObject.transform.quaternion.w);
            switch (collider.type) {
                case Collider_1.ColliderType.BOX:
                    let box_collider = collider;
                    cannon_shape = new CANNON.Box(new CANNON.Vec3(box_collider.world_size.x / 2.0, box_collider.world_size.y / 2.0, box_collider.world_size.z / 2.0));
                    let box_offset = this.gameObject.transform.inverseTransformPoint(collider.world_center.clone());
                    this.cannon_body.addShape(cannon_shape, new CANNON.Vec3(box_offset.x, box_offset.y, box_offset.z), rotation);
                    break;
                case Collider_1.ColliderType.SPHERE:
                    let sphere_collider = collider;
                    let radius = sphere_collider.world_radius;
                    cannon_shape = new CANNON.Sphere(radius);
                    let sphere_offset = this.gameObject.transform.inverseTransformPoint(collider.world_center.clone());
                    this.cannon_body.addShape(cannon_shape, new CANNON.Vec3(sphere_offset.x, sphere_offset.y, sphere_offset.z), rotation);
                    break;
            }
        }
    }
    recursiveSearch(id, object) {
        if (object.children.length == 0)
            return false;
        for (var child of object.children) {
            if (child.id == id)
                return true;
            this.recursiveSearch(id, child);
        }
    }
    _disableSimulation = false;
    cannon_body = null;
    colliders = [];
}
exports.Rigidbody = Rigidbody;
