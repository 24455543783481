"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorUtility = void 0;
const Inspector_1 = require("../editor/Inspector");
const TransformInspector_1 = require("../core/editor/TransformInspector");
const GameObjectInspector_1 = require("../core/editor/GameObjectInspector");
const Rigidbody_1 = require("../systems/physics/Rigidbody");
const RigidbodyInspector_1 = require("../systems/physics/editor/RigidbodyInspector");
const BoxCollider_1 = require("../systems/physics/BoxCollider");
const BoxColliderInspector_1 = require("../systems/physics/editor/BoxColliderInspector");
const SphereCollider_1 = require("../systems/physics/SphereCollider");
const SphereColliderInspector_1 = require("../systems/physics/editor/SphereColliderInspector");
const MeshRenderer_1 = require("../systems/mesh/MeshRenderer");
const three_1 = require("three");
const ToonMeshRendererInspector_1 = require("../systems/mesh/editor/ToonMeshRendererInspector");
const CameraInspector_1 = require("../core/editor/CameraInspector");
const Camera_1 = require("../core/Camera");
class EditorUtility {
    static addInspectors(gameObject, expanded = false) {
        var folder = new Inspector_1.Inspector(gameObject.name);
        folder.enabled = true;
        folder.folder.expanded = expanded;
        GameObjectInspector_1.GameObjectInspector.create(gameObject, folder);
        TransformInspector_1.TransformInspector.create(gameObject.transform, folder);
        // get components
        var rigidbody = gameObject.getComponent(Rigidbody_1.Rigidbody);
        if (rigidbody != null)
            RigidbodyInspector_1.RigidbodyInspector.create(rigidbody, folder);
        var boxCollider = gameObject.getComponent(BoxCollider_1.BoxCollider);
        if (boxCollider != null)
            BoxColliderInspector_1.BoxColliderInspector.create(boxCollider, folder);
        var sphereCollider = gameObject.getComponent(SphereCollider_1.SphereCollider);
        if (sphereCollider != null)
            SphereColliderInspector_1.SphereColliderInspector.create(sphereCollider, folder);
        var meshRenderer = gameObject.getComponent(MeshRenderer_1.MeshRenderer);
        if (meshRenderer != null) {
            if (meshRenderer.material instanceof three_1.MeshToonMaterial)
                ToonMeshRendererInspector_1.ToonMeshRendererInspector.create(meshRenderer, folder);
        }
        var camera = gameObject.getComponent(Camera_1.Camera);
        if (camera != null)
            CameraInspector_1.CameraInspector.create(camera, folder);
        return folder;
    }
}
exports.EditorUtility = EditorUtility;
