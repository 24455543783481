"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentInspector = void 0;
const Inspector_1 = require("../../editor/Inspector");
class ComponentInspector extends Inspector_1.Inspector {
    static create(component, parent = null) {
        const inspector = new ComponentInspector(component, parent);
        inspector.enabled = true;
        return inspector;
    }
    constructor(component, parent = null, expanded = false) {
        super(parent == null ? component.gameObject.name + " (" + component.constructor.name + ")" : component.constructor.name, parent, expanded);
        this.component = component;
        this.folder.addInput(this.base_params, 'enabled').on('change', (event) => {
            this.component.enabled = event.value;
        });
    }
    refresh() {
        super.refresh();
        this.base_params.enabled = this.component.enabled;
    }
    component = null;
    base_params = {
        enabled: false
    };
}
exports.ComponentInspector = ComponentInspector;
