"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformInspector = void 0;
const THREE = __importStar(require("three"));
const ComponentInspector_1 = require("./ComponentInspector");
const Application_1 = require("../Application");
/**
 * An inspector allowing you to visualise information about the transform of an object.
 * @todo Fix bug where position will sometimes be 'overwritten' by the inspector causing
 * laggy movement.
 */
class TransformInspector extends ComponentInspector_1.ComponentInspector {
    static create(component, parent = null, expanded = false) {
        const inspector = new TransformInspector(component, parent, expanded);
        inspector.enabled = true;
        return inspector;
    }
    constructor(component, parent = null, expanded = false) {
        super(component, parent, expanded);
        this.transform = component;
        this.axesHelper = new THREE.AxesHelper(2.0);
        this.refresh();
        this.folder.addInput(this.tweakable_params, 'gizmo').on('change', (event) => {
            this.axesHelper.visible = event.value;
        });
        this.folder.addInput(this.tweakable_params, 'local_position').on('change', (event) => {
            if (this.folder.expanded)
                this.transform.position = event.value;
        });
        this.folder.addInput(this.tweakable_params, 'local_rotation').on('change', (event) => {
            if (this.folder.expanded)
                this.transform.rotation.copy(event.value);
        });
        this.folder.addInput(this.tweakable_params, 'local_quaternion').on('change', (event) => {
            if (this.folder.expanded)
                this.transform.quaternion.copy(event.value);
        });
        this.folder.addInput(this.tweakable_params, 'local_scale').on('change', (event) => {
            if (this.folder.expanded)
                this.transform.scale.copy(event.value);
        });
        this.folder.addInput(this.tweakable_params, 'world_position', { disabled: true });
        this.folder.addInput(this.tweakable_params, 'world_quaternion', { disabled: true });
        this.folder.addInput(this.tweakable_params, 'world_scale', { disabled: true });
        this.folder.addInput(this.tweakable_params, 'up', { disabled: true });
        this.folder.addInput(this.tweakable_params, 'forward', { disabled: true });
        this.folder.addInput(this.tweakable_params, 'right', { disabled: true });
    }
    enable() {
        super.enable();
        Application_1.Application.activeScene.threejs_scene.add(this.axesHelper);
    }
    disable() {
        super.disable();
        Application_1.Application.activeScene.threejs_scene.remove(this.axesHelper);
    }
    refresh() {
        if (this.enabled == false)
            return;
        this.tweakable_params.local_position.copy(this.transform.position);
        this.tweakable_params.local_rotation.copy(this.transform.rotation);
        this.tweakable_params.local_quaternion.copy(this.transform.quaternion);
        this.tweakable_params.local_scale.copy(this.transform.scale);
        this.tweakable_params.world_position.copy(this.transform.global_position);
        this.tweakable_params.world_quaternion.copy(this.transform.global_quaternion);
        this.tweakable_params.world_scale.copy(this.transform.global_scale);
        this.tweakable_params.up.copy(this.transform.up);
        this.tweakable_params.forward.copy(this.transform.forward);
        this.tweakable_params.right.copy(this.transform.right);
        this.axesHelper.position.copy(this.transform.global_position);
        this.axesHelper.rotation.copy(this.transform.rotation);
        this.axesHelper.visible = this.folder.expanded;
        super.refresh();
    }
    transform = null;
    axesHelper = null;
    tweakable_params = {
        gizmo: true,
        local_position: new THREE.Vector3(),
        local_rotation: new THREE.Euler(),
        local_quaternion: new THREE.Quaternion(),
        local_scale: new THREE.Vector3(),
        world_position: new THREE.Vector3(),
        world_quaternion: new THREE.Quaternion(),
        world_scale: new THREE.Vector3(),
        up: new THREE.Vector3(),
        forward: new THREE.Vector3(),
        right: new THREE.Vector3()
    };
}
exports.TransformInspector = TransformInspector;
