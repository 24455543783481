import './style.css';
import { Application } from 'wobble-engine/engine/core/Application';
import { Physics } from 'wobble-engine/engine/systems/physics/Physics';
import { LightbulbScene } from "./game/scenes/LightbulbScene";
// initialise the application with the canvas
let startup = (event) => {
    document.removeEventListener('pointerdown', startup);
    event.preventDefault();
    Application.initialise(document.querySelector('canvas.webgl'), window.innerWidth, window.innerHeight);
    Physics.initialise();
    Application.loadScene(new LightbulbScene());
};
//document.addEventListener('pointerdown', startup);
Application.initialise(document.querySelector('canvas.webgl'), window.innerWidth, window.innerHeight);
Physics.initialise();
Application.loadScene(new LightbulbScene());
