import { Component } from "wobble-engine/engine/core/Component";
import { Input } from "wobble-engine/engine/systems/input/Input";
import { MathUtils } from "three";
export class OrbitCamera extends Component {
    constructor() {
        super(...arguments);
        // Public
        this.target = null;
        this.horizontal_speed = 5.0;
        this.vertical_speed = 5.0;
        this.vertical_offset = 1.0;
        this.distance = 3.0;
        this.min_y = 2.0;
        this.max_y = 10.0;
    }
    awake() {
        Input.events.addEventListener('OnPointerMove', (event) => {
            if (event.detail.event.shiftKey) {
                if (this.target == null)
                    return;
                // Update camera position
                this.gameObject.threejs_object3d.position.x = Math.sin(Input.mouseViewportPosition.x * Math.PI * 2) * this.horizontal_speed * this.distance;
                this.gameObject.threejs_object3d.position.z = Math.cos(Input.mouseViewportPosition.x * Math.PI * 2) * this.horizontal_speed * this.distance;
                this.gameObject.threejs_object3d.position.y = MathUtils.clamp(-Input.mouseViewportPosition.y * this.vertical_speed, this.min_y, this.max_y);
                var lookPosition = this.target.transform.position.clone();
                lookPosition.y += this.vertical_offset;
                this.gameObject.threejs_object3d.lookAt(lookPosition);
            }
        });
    }
    update(deltaTime) {
    }
}
