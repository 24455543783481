"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraInspector = void 0;
const ComponentInspector_1 = require("./ComponentInspector");
class CameraInspector extends ComponentInspector_1.ComponentInspector {
    static create(component, parent = null, expanded = false) {
        const inspector = new CameraInspector(component, parent, expanded);
        inspector.enabled = true;
        return inspector;
    }
    constructor(component, parent = null, expanded = false) {
        super(component, parent, expanded);
        this.camera = component;
        this.refresh();
        this.folder.addInput(this.tweakable_params, 'orthographic').on('change', (event) => {
            this.camera.orthographic = event.value;
        });
        this.folder.addInput(this.tweakable_params, 'orthographicSize', { min: 0.01, max: 50, step: 0.1 }).on('change', (event) => {
            this.camera.orthographicSize = event.value;
        });
        this.folder.addInput(this.tweakable_params, 'fieldOfView', { min: 0, max: 100, step: 1 }).on('change', (event) => {
            this.camera.fov = event.value;
        });
        this.folder.addInput(this.tweakable_params, 'aspect', { disabled: true }).on('change', (event) => {
            this.camera.aspect = event.value;
        });
    }
    refresh() {
        this.tweakable_params.orthographic = this.camera.orthographic;
        this.tweakable_params.orthographicSize = this.camera.orthographicSize;
        this.tweakable_params.fieldOfView = this.camera.fov;
        this.tweakable_params.aspect = this.camera.aspect;
        super.refresh();
    }
    camera = null;
    tweakable_params = {
        orthographic: false,
        orthographicSize: 5,
        fieldOfView: 60.0,
        aspect: 1.0
    };
}
exports.CameraInspector = CameraInspector;
