"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Application = exports._Application = void 0;
const THREE = __importStar(require("three"));
const CSS2DRenderer_1 = require("three/examples/jsm/renderers/CSS2DRenderer");
const EffectComposer_js_1 = require("three/examples/jsm/postprocessing/EffectComposer.js");
const RenderPass_js_1 = require("three/examples/jsm/postprocessing/RenderPass.js");
const FilmPass_js_1 = require("three/examples/jsm/postprocessing/FilmPass.js");
/**
 * The core application class of the engine. This is the entrypoint for initialising
 * the application with a specified canvas and internally handles the update and render loop
 * of the active scene.
 *
 * This class automatically creates a singleton on import so to initialise, just call App.initialise() and pass in the canvas
 * and desired width and height. Then create a scene and use loadScene to load it.
 *
 * ```
 * let canvas : HTMLCanvasElement = document.querySelector('canvas.webgl');
 * App.initialise( canvas, window.innerWidth, window.innerHeight );
 * let scene: Scene = new Scene("default");
 * App.loadScene( scene );
 * ```
 *
 * @todo Update class to actually be a singleton and throw errors if another Application instance is created
 */
class _Application {
    /**
     * @returns The dimensions of the application canvas
     */
    get canvasDimensions() { return new THREE.Vector2(window.innerWidth, window.innerHeight); }
    /**
     * @returns The currently active scene
     */
    get activeScene() { return this.scene; }
    /**
     * @returns The difference in time in seconds between the previous and current frame
     */
    get deltaTime() { return this.delta_time; }
    ;
    /**
     * @returns The elapsed time since the start of this application
     */
    get elapsedTime() { return this.elapsed_time; }
    ;
    /**
     * @returns Is the cursor currently hidden?
     */
    get hideCursor() { return this.canvas.style.cursor == 'none'; }
    /**
     * Set whether or not the cursor should be visible
     * @param value The cursor visibility
     */
    set hideCursor(value) { this.canvas.style.cursor = value ? 'none' : ''; }
    /**
     * @returns The current clear color of the renderer
     */
    get clearColor() {
        let color = new THREE.Color();
        this.renderer.getClearColor(color);
        return color;
    }
    /**
     * Set the clear color of the renderer
     * @param value
     */
    set clearColor(value) { this.renderer.setClearColor(value); }
    ;
    /**
     * Events invoked by the application class
     */
    events = new EventTarget();
    /**
     * Initialises the application. This should be called once, right at the
     * start of your app execution.
     *
     * @param canvas the canvas used to render this application
     * @param width the width of the game view
     * @param height the height of the game view
     */
    initialise(canvas, width, height) {
        this.canvas = canvas;
        this.canvas.style.zIndex = '-1';
        this.canvas.style.touchAction = "none";
        this.renderer = new THREE.WebGLRenderer({ canvas });
        this.renderer.setClearColor(new THREE.Color(0.9, 0.9, 0.9));
        this.renderer.setSize(width, height);
        this.time = Date.now();
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.renderer.shadowMap.enabled = true; //enable shadows
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        // create css renderer
        this.cssrendrerer = new CSS2DRenderer_1.CSS2DRenderer();
        this.cssrendrerer.setSize(width, height);
        this.cssrendrerer.domElement.style.position = 'absolute';
        this.cssrendrerer.domElement.style.top = '0px';
        this.cssrendrerer.domElement.style.zIndex = '0';
        this.cssrendrerer.domElement.style.pointerEvents = 'none';
        document.body.appendChild(this.cssrendrerer.domElement);
        this.composer = new EffectComposer_js_1.EffectComposer(this.renderer);
        window.addEventListener('resize', () => { this.onWindowResize(); });
    }
    /**
     * Load the specified scene. This will set the active scene and begin
     * the update and render process.
     * @param scene the scene to load
     * @todo This does not currently stop the old update and render loop so is probably bad if you
     * try to load another scene.
     */
    loadScene(scene) {
        const isFirstScene = (this.scene == null);
        this.scene = scene;
        this.scene.load(() => {
            this.scene.awake();
            const renderPass = new RenderPass_js_1.RenderPass(scene.threejs_scene, scene.cameras[0].threejs_camera);
            this.composer.addPass(renderPass);
            const filmPass = new FilmPass_js_1.FilmPass(0.1, 0, 0, 0.0);
            this.composer.addPass(filmPass);
            let detail = { scene: this.scene };
            this.events.dispatchEvent(new CustomEvent('OnSceneLoaded', { detail }));
            if (isFirstScene) {
                this.update();
                this.render();
            }
        });
    }
    /** @internal */
    constructor() { }
    update() {
        const currentTime = Date.now();
        this.delta_time = currentTime - this.time;
        this.time = currentTime;
        this.elapsed_time += this.deltaTime * 0.001;
        this.activeScene.update(this.delta_time);
        // update all gameobjects
        for (let i = 0; i < this.scene.gameObjects.length; i++)
            this.recursiveUpdate(this.scene.gameObjects[i], this.delta_time);
        let detail = { dt: this.delta_time };
        this.events.dispatchEvent(new CustomEvent("OnUpdate", { detail }));
        window.requestAnimationFrame(this.update.bind(this));
    }
    render() {
        if (this.scene.cameras.length != 0) {
            this.composer.render();
            //this.renderer.render(this.scene.threejs_scene, this.scene.cameras[0].threejs_camera);
            this.cssrendrerer.render(this.scene.threejs_scene, this.scene.cameras[0].threejs_camera);
        }
        window.requestAnimationFrame(this.render.bind(this));
    }
    onWindowResize() {
        for (var i = 0; i < this.scene.cameras.length; i++)
            this.scene.cameras[i].aspect = window.innerWidth / window.innerHeight;
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.cssrendrerer.setSize(window.innerWidth, window.innerHeight);
        let detail = { width: window.innerWidth, height: window.innerHeight };
        this.events.dispatchEvent(new CustomEvent('OnWindowResize', { detail }));
    }
    recursiveUpdate(gameObject, deltaTime) {
        gameObject.update(deltaTime);
        for (let child of gameObject.children)
            this.recursiveUpdate(child, deltaTime);
    }
    canvas = null;
    renderer = null;
    cssrendrerer = null;
    scene = null;
    time = Date.now();
    delta_time = 0.0;
    elapsed_time = 0.0;
    composer = null;
}
exports._Application = _Application;
/**
 * Singleton instance of the Application class. Use this instance instead of creating a new Application().
 */
exports.Application = new _Application();
