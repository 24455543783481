import * as THREE from "three";
import { Application } from "wobble-engine/engine/core/Application";
import { Component } from "wobble-engine/engine/core/Component";
import { Rigidbody } from "wobble-engine/engine/systems/physics/Rigidbody";
/** Component that can be added to a gameobject to make it grabbable.
 * This gameObject must also have a Collider component attached.
 * @requires Collider component attached to the gameObject
 */
export class IGrabbable extends Component {
    constructor() {
        super(...arguments);
        /** Should the cursor be hidden when a grab starts? */
        this.hideCursorOnGrab = true;
        /** Should the physics objects attached to this gameobject be disabled when grabbed? */
        this.disablePhysicsOnGrab = true;
        /** Should this object maintain its initial upright rotation even while being dragged? */
        this.maintainInitialRotation = true;
        /** Is this object currently held? */
        this.isHeld = false;
        /** Is this object currently grabbable? */
        this.isGrabbable = true;
        /**
         * The offset from the pivot of the gameObject to use as the 'grab point'
         */
        this.grabOffset = new THREE.Vector3(0, 0, 0);
        /** Should initial distance be maintained between grabbable and anchor point?*/
        this.maintainDistanceToAnchor = false;
        this.maxDistance = 1;
        this.anchorPoint = new THREE.Vector3(0, 0, 0);
        /** Event system used by this object */
        this.events = new EventTarget();
    }
    grab() {
        if (!this.enabled)
            return;
        let detail = {};
        this.events.dispatchEvent(new CustomEvent('OnGrab', { detail }));
        this.isHeld = true;
        if (this.hideCursorOnGrab)
            Application.hideCursor = true;
        if (this.disablePhysicsOnGrab) {
            // disable the physics
            let rigidbody = this.gameObject.getComponent(Rigidbody);
            if (rigidbody != null)
                rigidbody.disableSimulation = true;
        }
    }
    drag(position) {
        if (!this.enabled)
            return;
        this.updatePosition(position);
        //if( this.maintainInitialRotation )
        //this.gameObject.transform.quaternion = THREE.Quaternion.slerpFlat(transform.rotation, startRotation, Time.deltaTime * 10.0f);
        let detail = { position: position };
        this.events.dispatchEvent(new CustomEvent('OnDrag', { detail }));
    }
    drop() {
        if (!this.enabled)
            return;
        this.isHeld = false;
        if (this.hideCursorOnGrab)
            Application.hideCursor = false;
        if (this.disablePhysicsOnGrab) {
            // enable the physics
            let rigidbody = this.gameObject.getComponent(Rigidbody);
            if (rigidbody != null) {
                rigidbody.disableSimulation = false;
            }
        }
        let detail = {};
        this.events.dispatchEvent(new CustomEvent('OnDrop', { detail }));
    }
    updatePosition(position) {
        // set the position
        if (this.maintainDistanceToAnchor) {
            var vecToTarget = position.clone().sub(this.anchorPoint.clone().sub(this.grabOffset));
            if (vecToTarget.length() >= this.maxDistance) {
                vecToTarget = vecToTarget.normalize();
                position = this.anchorPoint.clone().add(vecToTarget.multiplyScalar(this.maxDistance)).sub(this.grabOffset);
            }
        }
        this.gameObject.transform.global_position = new THREE.Vector3(position.x, position.y, position.z).add(this.grabOffset);
    }
}
