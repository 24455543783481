"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Textures = exports.TextureDatabase = void 0;
const THREE = __importStar(require("three"));
/**
 * Database handling the loading, clearing and fetching of textures
 */
class TextureDatabase {
    /**
     * Load the texture into memory
     * @param id The unique id used to fetch this texture
     * @param path The path of the texture relative to the static folder
     * @returns The threejs texture. Null if no texture could be loaded.
     */
    load(id, path, onLoadComplete) {
        // load the texture at path
        this.textureLoader.load(path, (texture) => {
            // cache the texture
            this.textures[id] = texture;
            onLoadComplete(texture);
        }, () => { }, () => {
            // error occured
            console.log("Error encountered when loading texture at " + path);
            onLoadComplete(null);
        });
    }
    /**
     * Load the texture into memory asynchronously.
     * @param id The unique id used to fetch this texture
     * @param path The path of the texture relative to the static folder
     * @returns The threejs texture. Null if no texture could be loaded.
     */
    loadAsync(id, path) {
        return new Promise(resolve => {
            this.textureLoader.load(path, (texture) => {
                // cache the texture
                this.textures[id] = texture;
                resolve(texture);
            }, () => { }, () => {
                // error occured
                console.log("Error encountered when loading texture at " + path);
                resolve(null);
            });
        });
    }
    /**
     * Get the specified texture if it exists
     * @param id The id of the texture
     * @returns The threejs texture associated with this id
     */
    get(id) {
        return this.textures[id];
    }
    /**
     * Destroy the specified texture and remove it from the database
     * @param id The id of the texture to destroy
     */
    free(id) {
        this.textures.remove(id);
    }
    textures = {};
    textureLoader = new THREE.TextureLoader();
}
exports.TextureDatabase = TextureDatabase;
exports.Textures = new TextureDatabase();
