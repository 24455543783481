"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Materials = exports.MaterialDatabase = void 0;
const THREE = __importStar(require("three"));
/**
 * Database handling the loading, clearing and fetching of materials
 */
class MaterialDatabase {
    /**
     * Add the material to the internal list
     * @param id The unique id used for this material
     * @param material The material to add to the database
     */
    add(id, material) {
        this.materials[id] = material;
    }
    /**
     * Get the specified material if it exists
     * @param id The id of the material
     * @returns The threejs material associated with this id
     */
    get(id) {
        return this.materials[id];
    }
    /**
     * Destroy the specified material and remove it from the database
     * @param id The id of the material to destroy
     */
    free(id) {
        this.materials.remove(id);
    }
    /**
     * Create a basic material and add its to the internal list
     * @param id The id of the material
     * @param color The color of the material
     * @param map The texture applied to the material
     * @returns The generated threejs MeshBasicMaterial
     */
    createBasic(id, color, map) {
        var material = new THREE.MeshBasicMaterial({ color, map });
        this.add(id, material);
        return material;
    }
    /**
     * Create a normal material and add it to the internal list
     * @param id The id of the material
     * @param flatShaded Should this material be flat shaded
     * @returns The generated threejs MeshNormalMaterial
     */
    createNormal(id, flatShaded) {
        var material = new THREE.MeshNormalMaterial();
        material.flatShading = flatShaded;
        material.needsUpdate = true;
        this.add(id, material);
        return material;
    }
    /**
     * Create a matcap material and add it to the internal list
     * @param id The id of the material
     * @param map The matcap texture applied to the material
     * @returns The generated threejs MeshMatcapMaterial
     */
    createMatcap(id, map) {
        var material = new THREE.MeshMatcapMaterial();
        material.matcap = map;
        material.needsUpdate = true;
        this.add(id, material);
        return material;
    }
    /**
     * Create a toon material and add it to the internal list
     * @param id The id of the material
     * @param gradientMap The gradient texture applied to the material
     * @returns The generated threejs MeshToonMaterial
     */
    createToon(id, gradientMap) {
        var material = new THREE.MeshToonMaterial({ gradientMap });
        this.add(id, material);
        return material;
    }
    // INTERNAL
    materials = {};
}
exports.MaterialDatabase = MaterialDatabase;
exports.Materials = new MaterialDatabase();
