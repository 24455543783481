"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SphereCollider = void 0;
const Collider_1 = require("./Collider");
class SphereCollider extends Collider_1.Collider {
    get local_radius() { return this.radius; }
    get world_radius() { return this.gameObject.transform.global_scale.x * this.radius; }
    get local_center() { return this.center; }
    get world_center() { return this.gameObject.transform.transformPoint(this.center); }
    events = new EventTarget();
    /** @internal */
    constructor(radius, center) {
        super(center);
        // create a physics shape
        this.type = Collider_1.ColliderType.SPHERE;
        this.radius = radius;
    }
    ;
    radius = 1.0;
}
exports.SphereCollider = SphereCollider;
