import { Application } from "wobble-engine/engine/core/Application";
import { Input } from "wobble-engine/engine/systems/input/Input";
import { Physics } from "wobble-engine/engine/systems/physics/Physics";
export class InteractionSystem {
    constructor() {
        /** Event system used by this object */
        this.events = new EventTarget();
        Input.events.addEventListener('OnPointerDown', (event) => {
            this.beginGrab();
        });
        Input.events.addEventListener('OnPointerMove', (event) => {
            this.updateDrag();
        });
        Input.events.addEventListener('OnPointerUp', (event) => {
            this.beginRelease();
        });
    }
    beginGrab() {
        let camera = Application.activeScene.cameras[0];
        let interaction = new InteractionInfo();
        interaction.touch_position = Input.mouseViewportPosition;
        interaction.ray_origin = camera.gameObject.transform.position;
        interaction.ray_direction = camera.gameObject.transform.forward;
        let wasHit = Physics.raycastPhysicsFromCamera(interaction.touch_position, camera, 100.0, (hit) => {
            interaction.target = hit.collider.gameObject;
            interaction.contact_position = hit.point;
            interaction.contact_normal = hit.normal;
            interaction.contact_distance = camera.gameObject.transform.position.distanceTo(interaction.contact_position);
            let detail = { interaction: interaction };
            this.events.dispatchEvent(new CustomEvent('OnGrab', { detail }));
        });
        if (!wasHit) {
            let detail = { interaction: interaction };
            this.events.dispatchEvent(new CustomEvent('OnTap', { detail }));
        }
    }
    updateDrag() {
        let camera = Application.activeScene.cameras[0];
        let interaction = new InteractionInfo();
        interaction.touch_position = Input.mouseViewportPosition;
        interaction.ray_origin = camera.gameObject.transform.position;
        interaction.ray_direction = camera.gameObject.transform.forward;
        let detail = { interaction: interaction };
        this.events.dispatchEvent(new CustomEvent('OnDrag', { detail }));
    }
    beginRelease() {
        let detail = {};
        this.events.dispatchEvent(new CustomEvent('OnRelease', { detail }));
    }
}
export class InteractionInfo {
}
