var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as THREE from "three";
import { Component } from "wobble-engine/engine/core/Component";
import { SphereCollider } from "wobble-engine/engine/systems/physics/SphereCollider";
import { Rigidbody } from "wobble-engine/engine/systems/physics/Rigidbody";
import { MeshRenderer } from "wobble-engine/engine/systems/mesh/MeshRenderer";
import { Materials } from "wobble-engine/engine/systems/loaders/MaterialDatabase";
import { Textures } from "wobble-engine/engine/systems/loaders/TextureDatabase";
import { IGrabbable } from "../interfaces/IGrabbable";
import { EditorUtility } from "wobble-engine/engine/utility/EditorUtility";
import { WobbleShaderMaterial } from "wobble-engine/engine/shaders/wobble/WobbleShaderMaterial";
import { IPlug } from "../interfaces/IPlug";
import { Physics } from "wobble-engine/engine/systems/physics/Physics";
import { ISocket } from "../interfaces/ISocket";
import { EventEmitter } from "wobble-engine/engine/systems/events/EventEmitter";
import { FlipbookShaderMaterial } from "wobble-engine/engine/shaders/flipbook/FlipbookShaderMaterial";
import { Application } from "wobble-engine/engine/core/Application";
// Class representing the actor in the scene
export class Lightbulb extends Component {
    constructor() {
        super();
        this.MaxBrightness = 0.5;
        /**
         * Emitter of lightbulb events.
         * - OnToggle { on: boolean }
         * - OnStateUpdated { state: string}
         */
        this.events = new EventEmitter();
        // state
        this.state = "unavailable";
        this.isOn = false;
        this.color = new THREE.Color(1, 1, 1);
        // components
        this.plug = null;
        this.rigidbody = null;
        this.grabbable = null;
        this.collider = null;
        this.bulbMeshRenderer = null;
        this.baseMeshRenderer = null;
        this.light = null;
        // animation
        this.tween = null;
    }
    /**
     * Is the lightbulb currently connected to a socket?
     * @constructor
     */
    get IsPluggedIn() { return this.plug.connectedSocket != null; }
    ;
    /**
     * The current state of the lightbulb (eg. unavailable)
     * @constructor
     */
    get State() { return this.state; }
    set State(value) { this.updateState(value); }
    get Brightness() { return this.light.Intensity; }
    set Brightness(value) { this.light.Intensity = value; }
    /**
     * The current color of the lightbulb
     * @constructor
     */
    get Color() { return this.color.getHexString(); }
    set Color(value) {
        this.color = new THREE.Color(value);
        if (this.isOn)
            this.updateColor(this.color);
    }
    awake() {
        super.awake();
        this.setup();
    }
    update(dt) {
        super.update(dt);
        this.gameObject.transform.position = new THREE.Vector3(this.gameObject.transform.position.x, this.gameObject.transform.position.y, 0.0);
    }
    /**
     * Toggle the lightbulb on and off. This will dispatch an OnToggle event
     * if the on/off state actally changes
     * @param on
     */
    toggle(on) {
        if (on) {
            this.bulbMeshRenderer.material.alpha = 0.75;
            this.updateColor(this.color);
        }
        else {
            this.bulbMeshRenderer.material.alpha = 0.2;
            this.updateColor(new THREE.Color(0xcccccc));
        }
        let prev = this.isOn;
        this.isOn = on;
        // dispatch event if on/off state has changed
        if (this.isOn != prev)
            this.events.dispatchEvent("OnToggle", { on: this.isOn });
    }
    setup() {
        // setup mesh renderer
        var bulb = this.gameObject.getChildByName("Bulb");
        this.bulbMeshRenderer = bulb.getComponent(MeshRenderer);
        this.bulbMeshRenderer.receiveShadows = true;
        this.bulbMeshRenderer.castShadows = true;
        this.bulbMeshRenderer.material = new WobbleShaderMaterial(new THREE.Color(0.0, 0.0, 0.0));
        this.bulbMeshRenderer.material.side = THREE.DoubleSide;
        this.bulbMeshRenderer.material.diffuseColor = new THREE.Color(0xbfba8c);
        this.bulbMeshRenderer.material.transparent = true;
        this.bulbMeshRenderer.material.alpha = 0.5;
        var base = this.gameObject.getChildByName("Base");
        this.baseMeshRenderer = base.getComponent(MeshRenderer);
        this.baseMeshRenderer.material = new WobbleShaderMaterial(new THREE.Color(0.75, 0.75, 0.75));
        this.baseMeshRenderer.material.offset = 0.02;
        this.baseMeshRenderer.receiveShadows = true;
        this.baseMeshRenderer.castShadows = true;
        this.collider = this.gameObject.addComponent(new SphereCollider(2, new THREE.Vector3(0, 2.5, 0)));
        this.rigidbody = this.gameObject.addComponent(new Rigidbody());
        this.grabbable = this.gameObject.addComponent(new IGrabbable());
        this.grabbable.hideCursorOnGrab = true;
        this.plug = this.gameObject.addComponent(new IPlug());
        this.plug.plugOffset.y = 0.0;
        this.plug.compatibleSocketLayers.push("socket");
        this.rigidbody.isKinematic = true;
        this.toggle(false);
        this.rigidbody.recalculate();
        this.createFilament();
        this.grabbable.events.addEventListener("OnGrab", (event) => {
            if (this.tween != null)
                this.tween.kill();
        });
        this.grabbable.events.addEventListener("OnDrag", (event) => {
            // remove the plug when you start dragging
            if (this.plug.connectedSocket != null)
                this.plug.remove();
        });
        this.grabbable.events.addEventListener("OnDrop", (event) => {
            let colliders = Physics.overlapSphere(this.baseMeshRenderer.gameObject.transform.global_position, this.gameObject.transform.global_scale.length() * 2);
            for (let collider of colliders) {
                if (collider.gameObject === this.gameObject)
                    continue;
                let socket = collider.gameObject.getComponent(ISocket);
                if (socket != null && socket.currentPlug == null && socket.isCompatible(this.plug)) {
                    this.plug.insert(socket);
                    return;
                }
            }
            // add physics
            this.rigidbody.isKinematic = false;
        });
        this.plug.events.addEventListener("OnInsert", (event) => {
            this.grabbable.enabled = false;
            this.events.dispatchEvent("OnInsert", {});
        });
        this.plug.events.addEventListener("OnRemove", (event) => {
            this.grabbable.enabled = true;
            this.events.dispatchEvent("OnUnplug", {});
        });
        EditorUtility.addInspectors(this.gameObject);
    }
    createFilament() {
        return __awaiter(this, void 0, void 0, function* () {
            // load texture
            var texture = yield Textures.loadAsync('filament', '/textures/filament.png');
            texture.minFilter = THREE.NearestFilter;
            texture.magFilter = THREE.NearestFilter;
            texture.generateMipmaps = false;
            var filament = this.gameObject.getChildByName("Filament");
            var meshRenderer = filament.getComponent(MeshRenderer);
            // create flipbook material
            var flipbookMaterial = new FlipbookShaderMaterial(new THREE.Color(1.0, 1.0, 1.0), 2, 2, 4);
            flipbookMaterial.transparent = true;
            flipbookMaterial.map = texture;
            filament.transform.rotation = new THREE.Euler(0, Math.PI, 0);
            Materials.add("filament", flipbookMaterial);
            meshRenderer.material = flipbookMaterial;
            flipbookMaterial.depthTest = false;
        });
    }
    updateState(newState) {
        let previousState = this.state;
        this.state = newState;
        // dispatch event but only in cases where the state actually changed
        if (this.state != previousState)
            this.events.dispatchEvent("OnStateUpdated", { state: this.state });
    }
    updateColor(newColor) {
        this.bulbMeshRenderer.material.diffuseColor = new THREE.Color(newColor.r, newColor.g, newColor.b);
        if (this.light != null)
            this.light.Color = newColor;
        Application.clearColor = new THREE.Color(newColor.r, newColor.g, newColor.b);
    }
}
