"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component = void 0;
/**
 * Base component class that can be extended and added to a gameobject
 * All components (core and general scripts) should extend this class.
 */
class Component {
    /** The GameObject that owns this component */
    get gameObject() { return this.game_object; }
    ;
    /** Is this component currently enabled? */
    enabled = true;
    /**
     * Initialise this component with its owned gameObject. This is
     * called on creation to ensure the gameobject is set before awake is called
     * @param gameObject The gameobject this component is attached to
     */
    initialise(gameObject) {
        this.game_object = gameObject;
    }
    /**
     * Called when this component is first added (after initialise).
     * Override this function to implement 'startup' code for the component
     */
    awake() {
    }
    /**
     * Called on every update frame. Override this function to implement update code.
     * @param deltaTime Change in time between the previous and current frame. Equal to App.deltaTime
     */
    update(deltaTime) {
    }
    /**
    * Called when this component is destroyed. Likely due to the gameObject being destroyed.
    */
    destroy() {
    }
    // INTERNAL
    /** @internal */
    constructor() { }
    ;
    game_object = null;
}
exports.Component = Component;
