import { Component } from "wobble-engine/engine/core/Component";
export class IToggleable extends Component {
    constructor() {
        super(...arguments);
        /** Event target object for this component */
        this.events = new EventTarget();
        this.isOn = false;
    }
    // The current state of the toggle
    get IsOn() { return this.isOn; }
    toggle() {
        if (!this.enabled)
            return;
        // toggle!
        this.isOn = !this.isOn;
        let detail = { state: this.isOn };
        this.events.dispatchEvent(new CustomEvent('OnToggle', { detail }));
    }
}
