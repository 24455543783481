import { Application } from "wobble-engine/engine/core/Application";
import { Collider } from "wobble-engine/engine/systems/physics/Collider";
import { Component } from "wobble-engine/engine/core/Component";
import { Input } from "wobble-engine/engine/systems/input/Input";
import { IGrabbable } from "../interfaces/IGrabbable";
export class Grabber extends Component {
    constructor(interactionSystem) {
        super();
        this.interactionSystem = null;
        this.currentGrabbable = null;
        this.dragDistance = 1.0;
        this.interactionSystem = interactionSystem;
    }
    awake() {
        this.interactionSystem.events.addEventListener('OnGrab', (event) => {
            if (this.currentGrabbable != null)
                return;
            let interaction = event.detail.interaction;
            if (interaction.target != null) {
                let collider = interaction.target.getComponent(Collider);
                if (collider != null) {
                    let grabbable = this.getGrabbable(collider);
                    if (grabbable != null && grabbable.enabled == true) {
                        this.currentGrabbable = grabbable;
                        this.dragDistance = interaction.contact_distance;
                        // calculate touch position in world space
                        let camera = Application.activeScene.cameras[0];
                        let position = camera.screenToWorld(Input.mouseViewportPosition, this.dragDistance);
                        this.currentGrabbable.grabOffset = interaction.target.transform.global_position.clone().sub(position);
                        this.currentGrabbable.grab();
                    }
                }
            }
        });
        this.interactionSystem.events.addEventListener('OnDrag', (event) => {
            if (event.detail.interaction != null && this.currentGrabbable != null) {
                let camera = Application.activeScene.cameras[0];
                let position = camera.screenToWorld(Input.mouseViewportPosition, this.dragDistance);
                this.currentGrabbable.drag(position);
            }
        });
        this.interactionSystem.events.addEventListener('OnRelease', (event) => {
            if (this.currentGrabbable != null) {
                this.currentGrabbable.drop();
                this.currentGrabbable = null;
                this.dragDistance = 1.0;
            }
        });
    }
    /** Get the first active grabbable attached to this collider */
    getGrabbable(collider) {
        let curr = collider;
        while (curr != null) {
            let grabbable = curr.gameObject.getComponent(IGrabbable);
            if (grabbable != null && grabbable.enabled)
                return grabbable;
            if (curr.gameObject.parent == null)
                return null;
            let parentCollider = curr.gameObject.parent.getComponent(Collider);
            if (parentCollider != null && parentCollider.rigidbody == collider.rigidbody)
                curr = parentCollider;
            else
                curr = null;
        }
    }
    /**
     * Force the grabber to grab the passed in object
     * @param grabbable
     */
    grab(grabbable) {
        if (this.currentGrabbable != null)
            return;
        if (grabbable != null && grabbable.enabled == true) {
            this.currentGrabbable = grabbable;
            this.currentGrabbable.grab();
        }
    }
    /**
     * Force the grabber to drop its current object
     * @param grabbable
     */
    drop() {
        if (this.currentGrabbable != null) {
            this.currentGrabbable.drop();
            this.currentGrabbable = null;
            this.dragDistance = 1.0;
        }
    }
}
