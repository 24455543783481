"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gltfParser = exports.GLTFParser = void 0;
const GLTFLoader_js_1 = require("three/examples/jsm/loaders/GLTFLoader.js");
const GameObject_1 = require("../../core/GameObject");
const MeshRenderer_1 = require("../mesh/MeshRenderer");
const SkinnedMeshRenderer_1 = require("../mesh/SkinnedMeshRenderer");
/**
 * Database handling the loading, clearing and fetching of gltf files
 */
class GLTFParser {
    /**
     * Parse the specified gltf into a set of gameobjects
     * @param path The path of the gltf file relative to the static folder
     * @param onParseComplete The event invoked once the file has been parsed. This will hold
     * a set of gameobjects loaded from the gltf.
     */
    parse(path, onParseComplete) {
        // load the gltf file at path
        this.gltfLoader.load(path, (gltf) => {
            let gameobjects = [];
            this.parseChildren(gltf.scene.children, null, gameobjects);
            onParseComplete(gameobjects);
        }, () => { }, () => {
            // error occured
            console.log("Error encountered when loading gltf file at " + path);
            onParseComplete(null);
        });
    }
    async parseAsync(path) {
        return new Promise(resolve => {
            this.gltfLoader.load(path, (gltf) => {
                let gameobjects = [];
                this.parseChildren(gltf.scene.children, null, gameobjects);
                resolve(gameobjects);
            }, () => { }, () => {
                // error occured
                console.log("Error encountered when loading gltf file at " + path);
                resolve(null);
            });
        });
    }
    parseMeshes(path, onParseComplete) {
        // load the gltf file at path
        this.gltfLoader.load(path, (gltf) => {
            let meshes = [];
            this.parseChildrenMeshes(gltf.scene.children, meshes);
            onParseComplete(meshes);
        }, () => { }, () => {
            // error occured
            console.log("Error encountered when loading gltf file at " + path);
            onParseComplete(null);
        });
    }
    parseChildren(children, parent, gameObjects) {
        // copy children array
        const _children = Object.assign([], children);
        for (let gltf_object of _children) {
            let gameObject = null;
            switch (gltf_object.type) {
                case 'Object3D':
                    gameObject = this.createGameObject(gltf_object, parent, gameObjects);
                    break;
                case 'Mesh':
                    gameObject = this.createGameObject(gltf_object, parent, gameObjects);
                    let mesh = gltf_object;
                    gameObject.addComponent(new MeshRenderer_1.MeshRenderer(mesh));
                    break;
                case 'SkinnedMesh':
                    gameObject = this.createGameObject(gltf_object, parent, gameObjects);
                    let skinnedMesh = gltf_object;
                    gameObject.addComponent(new SkinnedMeshRenderer_1.SkinnedMeshRenderer(skinnedMesh));
                    break;
                case 'Bone':
                    //gameObject = parent;
                    break;
                default:
                    console.log("Unable to create object from type " + gltf_object.type);
                    break;
            }
            this.parseChildren(gltf_object.children, gameObject, gameObjects);
        }
    }
    createGameObject(gltf_object, parent, gameObjects) {
        // create a new gameobject
        let gameObject = new GameObject_1.GameObject(gltf_object.name);
        gameObject.transform.position.copy(gltf_object.position);
        gameObject.transform.rotation.copy(gltf_object.rotation);
        gameObject.transform.scale.copy(gltf_object.scale);
        if (parent != null)
            gameObject.setParent(parent, true);
        else
            gameObjects.push(gameObject);
        return gameObject;
    }
    parseChildrenMeshes(children, meshes) {
        for (let gltf_object of children) {
            if (gltf_object.type == 'Mesh') {
                meshes.push(gltf_object);
            }
            this.parseChildrenMeshes(gltf_object.children, meshes);
        }
    }
    // INTERNAL
    gltfLoader = new GLTFLoader_js_1.GLTFLoader();
}
exports.GLTFParser = GLTFParser;
exports.gltfParser = new GLTFParser();
