"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RigidbodyInspector = void 0;
const THREE = __importStar(require("three"));
const Application_1 = require("../../../core/Application");
const MaterialDatabase_1 = require("../../loaders/MaterialDatabase");
const ComponentInspector_1 = require("../../../core/editor/ComponentInspector");
const cannon_es_1 = require("cannon-es");
class RigidbodyInspector extends ComponentInspector_1.ComponentInspector {
    static create(component, parent = null, expanded = false) {
        const inspector = new RigidbodyInspector(component, parent, expanded);
        inspector.enabled = true;
        return inspector;
    }
    constructor(component, parent = null, expanded = false) {
        super(component, parent, expanded);
        this.rigidbody = component;
        this.axesHelper = new THREE.AxesHelper(2.0);
        this.mesh = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), MaterialDatabase_1.Materials.get("wireframe"));
        Application_1.Application.activeScene.threejs_scene.add(this.mesh);
        this.refresh();
        this.folder.addInput(this.tweakable_params, 'isKinematic').on('change', (event) => {
            this.rigidbody.isKinematic = event.value;
        });
        this.folder.addInput(this.tweakable_params, 'disableSimulation').on('change', (event) => {
            this.rigidbody.disableSimulation = event.value;
        });
        this.refresh();
    }
    refresh() {
        super.refresh();
        this.tweakable_params.isKinematic = this.rigidbody.isKinematic;
        this.tweakable_params.disableSimulation = this.rigidbody.disableSimulation;
        // render the shape
        var shape = this.rigidbody.cannon_body.shapes[0];
        this.rigidbody.cannon_body.updateAABB();
        var pos = new cannon_es_1.Vec3();
        var quaternion = new cannon_es_1.Quaternion();
        var min = this.rigidbody.cannon_body.aabb.lowerBound;
        var max = this.rigidbody.cannon_body.aabb.upperBound;
        var vecBetween = min.addScaledVector(-1, max);
        pos = min.clone().addScaledVector(-0.5, vecBetween);
        this.mesh.visible = this.folder.expanded;
        this.mesh.position.copy(new THREE.Vector3(pos.x, pos.y, pos.z));
        this.mesh.scale.copy(new THREE.Vector3(vecBetween.x, vecBetween.y, vecBetween.z));
        this.axesHelper.position.copy(new THREE.Vector3(this.rigidbody.cannon_body.position.x, this.rigidbody.cannon_body.position.y, this.rigidbody.cannon_body.position.z));
        this.axesHelper.visible = this.folder.expanded;
    }
    enable() {
        super.enable();
        Application_1.Application.activeScene.threejs_scene.add(this.axesHelper);
    }
    disable() {
        super.disable();
        Application_1.Application.activeScene.threejs_scene.remove(this.axesHelper);
    }
    mesh = null;
    rigidbody = null;
    axesHelper = null;
    tweakable_params = {
        isKinematic: false,
        disableSimulation: false
    };
}
exports.RigidbodyInspector = RigidbodyInspector;
