"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameObjectInspector = void 0;
const Inspector_1 = require("../../editor/Inspector");
/**
 * An inspector allowing you to visualise information about the transform of an object.
 * @todo Fix bug where position will sometimes be 'overwritten' by the inspector causing
 * laggy movement.
 */
class GameObjectInspector extends Inspector_1.Inspector {
    static create(gameObject, parent = null, expanded = false) {
        const inspector = new GameObjectInspector(gameObject, parent, expanded);
        inspector.enabled = true;
        return inspector;
    }
    constructor(gameObject, parent = null, expanded = false) {
        super(gameObject.name, parent, expanded);
        this.gameObject = gameObject;
        this.refresh();
        this.folder.addInput(this.tweakable_params, 'parent', { disabled: true });
        this.folder.addInput(this.tweakable_params, 'children', { disabled: true });
    }
    refresh() {
        if (this.enabled == false)
            return;
        this.tweakable_params.parent = this.gameObject.parent == null ? "none" : this.gameObject.parent.name + " " + this.gameObject.parent.threejs_object3d.id;
        this.tweakable_params.children = this.gameObject.threejs_object3d.children.length;
        super.refresh();
    }
    gameObject = null;
    tweakable_params = {
        parent: "",
        children: 0
    };
}
exports.GameObjectInspector = GameObjectInspector;
