"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transform = void 0;
const THREE = __importStar(require("three"));
const Component_1 = require("./Component");
const Application_1 = require("./Application");
class Transform extends Component_1.Component {
    // PUBLIC
    // The world position of this transform 
    get global_position() { this.gameObject.threejs_object3d.getWorldPosition(this.world_pos); return this.world_pos; }
    set global_position(value) {
        let parent = this.gameObject.threejs_object3d.parent;
        Application_1.Application.activeScene.threejs_scene.attach(this.gameObject.threejs_object3d);
        this.gameObject.threejs_object3d.position.set(value.x, value.y, value.z);
        this.gameObject.threejs_object3d.updateMatrix();
        if (parent != null)
            parent.attach(this.gameObject.threejs_object3d);
    }
    // The world position of this transform 
    get global_quaternion() { this.gameObject.threejs_object3d.getWorldQuaternion(this.world_rotation); return this.world_rotation; }
    set global_quaternion(value) {
        let parent = this.gameObject.threejs_object3d.parent;
        Application_1.Application.activeScene.threejs_scene.attach(this.gameObject.threejs_object3d);
        this.gameObject.threejs_object3d.quaternion.copy(value);
        this.gameObject.threejs_object3d.updateMatrix();
        if (parent != null)
            parent.attach(this.gameObject.threejs_object3d);
    }
    // The world scale of this transform 
    get global_scale() { this.gameObject.threejs_object3d.getWorldScale(this.world_scale); return this.world_scale; }
    set global_scale(value) {
        let parent = this.gameObject.threejs_object3d.parent;
        Application_1.Application.activeScene.threejs_scene.attach(this.gameObject.threejs_object3d);
        this.gameObject.threejs_object3d.scale.set(value.x, value.y, value.z);
        this.gameObject.threejs_object3d.updateMatrix();
        if (parent != null)
            parent.attach(this.gameObject.threejs_object3d);
    }
    get position() { return this.gameObject.threejs_object3d.position; }
    set position(value) { this.gameObject.threejs_object3d.position.copy(value); }
    // The world rotation of this transform 
    get quaternion() { return this.gameObject.threejs_object3d.quaternion; }
    set quaternion(value) { this.gameObject.threejs_object3d.quaternion.copy(value); }
    // The world euler rotation of this transform
    get rotation() { return this.gameObject.threejs_object3d.rotation; }
    set rotation(value) { this.gameObject.threejs_object3d.rotation.copy(value); }
    // The world scale of this transform
    get scale() { return this.gameObject.threejs_object3d.scale; }
    set scale(value) { this.gameObject.threejs_object3d.scale.copy(value); }
    // The up vector of this transform
    get up() { return new THREE.Vector3(0.0, 1.0, 0.0).applyQuaternion(this.global_quaternion); }
    get forward() { return new THREE.Vector3(0.0, 0.0, 1.0).applyQuaternion(this.global_quaternion); }
    get right() { return new THREE.Vector3(1.0, 0.0, 0.0).applyQuaternion(this.global_quaternion); }
    /** Transform the point from local space of this object to world space */
    transformPoint(vec) {
        // this is essential to calculate the correct local to world (at least if you're doing it on the first frame)
        this.gameObject.threejs_object3d.updateWorldMatrix(true, true);
        return this.gameObject.threejs_object3d.localToWorld(vec.clone());
    }
    /** Transform the point from world space to the local space of this transform */
    inverseTransformPoint(vec) {
        // this is essential to calculate the correct world to local (at least if you're doing it on the first frame)
        this.gameObject.threejs_object3d.updateWorldMatrix(true, true);
        return this.gameObject.threejs_object3d.worldToLocal(vec.clone());
    }
    /** Transform the vector from local space to world space utilising scale */
    transformVector(vec) {
        // no need to calculate world matrix.
        return vec.clone().applyQuaternion(this.global_quaternion).multiply(this.global_scale);
    }
    /** Transform the vector from world space to local space utilising scale */
    inverseTransformVector(vec) {
        // no need to calculate world matrix.
        return vec.clone().applyQuaternion(this.global_quaternion.clone().invert()).divide(this.global_scale);
    }
    /** Transform the direction vector from local space to world space */
    transformDirection(vec) {
        // no need to calculate world matrix.
        return vec.clone().applyQuaternion(this.global_quaternion);
    }
    /** Transform the direction vector from world space to local space*/
    inverseTransformDirection(vec) {
        // no need to calculate world matrix.
        return vec.clone().applyQuaternion(this.global_quaternion.clone().invert());
    }
    constructor() {
        super();
    }
    initialise(gameObject) {
        super.initialise(gameObject);
    }
    world_rotation = new THREE.Quaternion;
    world_pos = new THREE.Vector3;
    world_scale = new THREE.Vector3;
}
exports.Transform = Transform;
