"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Camera = void 0;
const Component_1 = require("./Component");
const THREE = __importStar(require("three"));
const Application_1 = require("./Application");
class Camera extends Component_1.Component {
    // PUBLIC
    // The field of view of the camera. Only valid when not in orthographic mode
    get fov() { return this.fieldOfView; }
    set fov(value) {
        this.fieldOfView = value;
        if (!this.orthographic)
            this.setupPerspective(value, this.aspect);
    }
    // Is this camera using an orthographic projection?
    get orthographic() { return this.isOrthographic; }
    set orthographic(value) {
        // we may need to switch cameras
        if (value == true)
            this.setupOrthographic(this._orthographicSize, this.aspectRatio);
        else
            this.setupPerspective(this.fieldOfView, this.aspectRatio);
    }
    /** The orthographic size of the camera. Only relevant when orthographic is true. */
    get orthographicSize() { return this._orthographicSize; }
    set orthographicSize(value) {
        if (this.isOrthographic) {
            this._orthographicSize = value;
            this.setupOrthographic(this._orthographicSize, this.aspectRatio);
        }
    }
    // The aspect ratio of the camera
    get aspect() { return this.aspectRatio; }
    set aspect(value) {
        if (!this.orthographic)
            this.setupPerspective(this.fieldOfView, value);
        else
            this.setupOrthographic(this._orthographicSize, value);
    }
    /** The audio listener attached to this camera */
    get audioListener() { return this._audioListener; }
    constructor(aspect) {
        super();
        // set reasonable defaults
        this.fieldOfView = 60;
        this.aspectRatio = aspect;
        this.nearClip = 0.1;
        this.farClip = 100;
        this._orthographicSize = 7.5;
        this.raycaster = new THREE.Raycaster();
    }
    initialise(gameObject) {
        super.initialise(gameObject);
        // create the internal camera
        this.setupPerspective(this.fieldOfView, this.aspect);
        this._audioListener = new THREE.AudioListener();
        Application_1.Application.activeScene.threejs_scene.add(this._audioListener);
    }
    screenToWorld(mouse, distance) {
        this.raycaster.setFromCamera(mouse, this.threejs_camera);
        return this.raycaster.ray.origin.clone().add(this.raycaster.ray.direction.multiplyScalar(distance));
    }
    /**
     * Returns a ray going through screen point
     * @param mouse
     */
    screenToRay(mouse) {
        this.raycaster.setFromCamera(mouse, this.threejs_camera);
        return new THREE.Ray(this.raycaster.ray.origin, this.raycaster.ray.direction);
    }
    // INTERNAL
    _orthographicSize = 5;
    fieldOfView = 60;
    aspectRatio = 1.0;
    isOrthographic = false;
    nearClip = 0.1;
    farClip = 100;
    dimensions = new THREE.Vector2(1, 1);
    _audioListener = null;
    raycaster = null;
    threejs_camera = null; // the threejs internal camera
    setupOrthographic(size, aspect) {
        let left = size * aspect / -2;
        let right = size * aspect / 2;
        let top = size / 2;
        let bottom = -size / 2;
        if (this.threejs_camera == null || this.isOrthographic == false) {
            if (this.threejs_camera != null)
                this.gameObject.threejs_object3d.remove(this.threejs_camera);
            this.threejs_camera = new THREE.OrthographicCamera(left, right, top, bottom, this.nearClip, this.farClip);
            // rotate the camera 180 degress on y because of a weird quirk where camera's look down -z
            // so lookAt doesn't work properly for childed cameras.
            this.threejs_camera.rotateOnAxis(new THREE.Vector3(0, 1, 0), (Math.PI / 180.0) * 180.0);
            this.gameObject.threejs_object3d.add(this.threejs_camera);
        }
        else {
            let camera = this.threejs_camera;
            camera.left = left;
            camera.right = right;
            camera.top = top;
            camera.bottom = bottom;
            camera.updateProjectionMatrix();
        }
        this.isOrthographic = true;
        this._orthographicSize = size;
        this.aspectRatio = aspect;
    }
    setupPerspective(fov, aspect) {
        if (this.threejs_camera == null || this.isOrthographic == true) {
            if (this.threejs_camera != null)
                this.gameObject.threejs_object3d.remove(this.threejs_camera);
            this.threejs_camera = new THREE.PerspectiveCamera(fov, aspect, this.nearClip, this.farClip);
            // rotate the camera 180 degress on y because of a weird quirk where camera's look down -z
            // so lookAt doesn't work properly for childed cameras.
            this.threejs_camera.rotateOnAxis(new THREE.Vector3(0, 1, 0), (Math.PI / 180.0) * 180.0);
            this.gameObject.threejs_object3d.add(this.threejs_camera);
        }
        else {
            let camera = this.threejs_camera;
            camera.aspect = aspect;
            camera.fov = fov;
            camera.updateProjectionMatrix();
        }
        this.isOrthographic = false;
        this.aspectRatio = aspect;
    }
}
exports.Camera = Camera;
