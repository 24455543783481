"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EDITOR = exports.Editor = void 0;
const THREE = __importStar(require("three"));
const tweakpane_1 = require("tweakpane");
const MaterialDatabase_1 = require("../systems/loaders/MaterialDatabase");
class Editor {
    // PUBLIC
    constructor() {
        this.refresh();
        let material = MaterialDatabase_1.Materials.createBasic("wireframe", new THREE.Color(0.2, 0.2, 0.2), null);
        material.wireframe = true;
        this.pane.element.style.zIndex = '100';
    }
    refresh() {
        this.pane.refresh();
        window.requestAnimationFrame(this.refresh.bind(this));
    }
    static addInspector(type, gameObject) {
        return new type(gameObject.name + "(" + type + ")");
    }
    // INTERNAL
    pane = new tweakpane_1.Pane({ title: 'editor', expanded: true });
}
exports.Editor = Editor;
exports.EDITOR = new Editor();
