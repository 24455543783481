"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SphereColliderInspector = void 0;
const THREE = __importStar(require("three"));
const Application_1 = require("../../../core/Application");
const MaterialDatabase_1 = require("../../loaders/MaterialDatabase");
const ComponentInspector_1 = require("../../../core/editor/ComponentInspector");
class SphereColliderInspector extends ComponentInspector_1.ComponentInspector {
    static create(component, parent = null, expanded = false) {
        const inspector = new SphereColliderInspector(component, parent, expanded);
        inspector.enabled = true;
        return inspector;
    }
    constructor(component, parent = null, expanded = false) {
        super(component, parent, expanded);
        this.axesHelper = new THREE.AxesHelper(2.0);
        // create box representations
        this.collider = component;
        this.mesh = new THREE.Mesh(new THREE.SphereGeometry(1, 10, 10), MaterialDatabase_1.Materials.get("wireframe"));
        Application_1.Application.activeScene.threejs_scene.add(this.mesh);
        this.refresh();
        this.folder.addInput(this.tweakable_params, 'local_radius', { disabled: true });
        this.folder.addInput(this.tweakable_params, 'world_radius', { disabled: true });
        this.folder.addInput(this.tweakable_params, 'local_center', { disabled: true });
        this.folder.addInput(this.tweakable_params, 'world_center', { disabled: true });
        this.folder.addInput(this.tweakable_params, 'rigidbody', { disabled: true });
    }
    enable() {
        super.enable();
        Application_1.Application.activeScene.threejs_scene.add(this.axesHelper);
    }
    disable() {
        super.disable();
        Application_1.Application.activeScene.threejs_scene.remove(this.axesHelper);
    }
    refresh() {
        super.refresh();
        this.mesh.visible = this.folder.expanded;
        this.tweakable_params.local_radius = this.collider.local_radius;
        this.tweakable_params.world_radius = this.collider.world_radius;
        this.tweakable_params.local_center = this.collider.local_center;
        this.tweakable_params.world_center = this.collider.world_center;
        this.tweakable_params.rigidbody = this.collider.rigidbody == null ? "none" : this.collider.rigidbody.gameObject.name;
        this.mesh.position.copy(this.collider.world_center);
        this.mesh.quaternion.copy(this.collider.gameObject.transform.global_quaternion);
        this.mesh.scale.set(this.collider.world_radius, this.collider.world_radius, this.collider.world_radius);
        this.axesHelper.visible = false;
    }
    collider = null;
    mesh = null;
    axesHelper = null;
    tweakable_params = {
        local_radius: 1.0,
        world_radius: 1.0,
        local_center: new THREE.Vector3(),
        world_center: new THREE.Vector3(),
        rigidbody: "",
    };
}
exports.SphereColliderInspector = SphereColliderInspector;
