// Import the functions you need from the SDKs you need
import * as FB_App from "firebase/app";
import * as FB_Analytics from "firebase/analytics";
import * as FB_Database from 'firebase/database';
export class LightbulbAPI {
    constructor() {
        this.app = null;
        this.database = null;
        this.analytics = null;
        const firebaseConfig = {
            apiKey: "AIzaSyBUdLaFGTrHE_g6lXc3DPnIUmHx7CewPlw",
            authDomain: "wobbleware-lightbulb.firebaseapp.com",
            projectId: "wobbleware-lightbulb",
            storageBucket: "wobbleware-lightbulb.appspot.com",
            messagingSenderId: "380150381522",
            appId: "1:380150381522:web:7c918c0e183878d30c660b",
            measurementId: "G-PXDM2PZD1E",
            databaseURL: "https://wobbleware-lightbulb-default-rtdb.firebaseio.com/"
        };
        // Initialize Firebase
        this.app = FB_App.initializeApp(firebaseConfig);
        this.database = FB_Database.getDatabase(this.app);
        this.analytics = FB_Analytics.getAnalytics(this.app);
    }
    // Write data to location of the database
    write(location, data) {
        FB_Database.set(FB_Database.ref(this.database, location), data);
    }
    // Read the data from the location in the database
    read(location, event) {
        var ref = FB_Database.ref(this.database, location);
        FB_Database.get(ref).then((snapshot) => {
            var value = null;
            if (snapshot.exists())
                value = snapshot.val();
            event(value);
        });
    }
    // Listen to changes at a particular location in the database. When a change
    // is detected, this invokes event with the snapshot passed in.
    listen(location, event) {
        var locationReference = FB_Database.ref(this.database, location);
        FB_Database.onValue(locationReference, event);
    }
}
