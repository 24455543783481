import * as THREE from "three";
import { Component } from "wobble-engine/engine/core/Component";
export class ISocket extends Component {
    constructor() {
        super(...arguments);
        this.socketOffset = new THREE.Vector3();
        this.socketLayerID = "";
        this.events = new EventTarget();
        this.connectedPlug = null;
    }
    get socketPosition() { return this.gameObject.transform.transformPoint(this.socketOffset); }
    get currentPlug() { return this.connectedPlug; }
    ;
    receive(plug) {
        if (!this.enabled)
            return;
        this.connectedPlug = plug;
        let detail = { plug: this.connectedPlug };
        this.events.dispatchEvent(new CustomEvent('OnReceive', { detail }));
    }
    release() {
        if (!this.enabled)
            return;
        let plug = this.connectedPlug;
        this.connectedPlug = null;
        let detail = { plug: plug };
        this.events.dispatchEvent(new CustomEvent('OnRelease', { detail }));
    }
    isCompatible(plug) {
        for (var plugLayer of plug.compatibleSocketLayers) {
            if (plugLayer == this.socketLayerID)
                return true;
        }
    }
}
