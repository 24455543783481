"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColliderType = exports.Collider = void 0;
const Component_1 = require("../../core/Component");
const Physics_1 = require("./Physics");
const THREE = __importStar(require("three"));
class Collider extends Component_1.Component {
    /** The center of this collider relative to its rigidbody */
    events = new EventTarget();
    /** @internal */
    constructor(center) {
        super();
        this.center = center;
    }
    ;
    containsObject(object) {
        return this.recursiveSearch(object.id, this.gameObject.threejs_object3d);
    }
    awake() {
        Physics_1.Physics.register(this);
    }
    destroy() {
        Physics_1.Physics.unregister(this);
    }
    recursiveSearch(id, object) {
        if (object.children.length == 0)
            return false;
        for (var child of object.children) {
            if (child.id == id)
                return true;
            this.recursiveSearch(id, child);
        }
    }
    rigidbody = null;
    type = ColliderType.SPHERE;
    center = new THREE.Vector3();
}
exports.Collider = Collider;
var ColliderType;
(function (ColliderType) {
    ColliderType[ColliderType["SPHERE"] = 0] = "SPHERE";
    ColliderType[ColliderType["BOX"] = 1] = "BOX";
})(ColliderType = exports.ColliderType || (exports.ColliderType = {}));
