import * as THREE from "three";
import { Component } from "wobble-engine/engine/core/Component";
import { MathUtility } from "wobble-engine/engine/utility/MathUtility";
import { Rigidbody } from "wobble-engine/engine/systems/physics/Rigidbody";
export class IPlug extends Component {
    constructor() {
        super(...arguments);
        this.childToSocket = true;
        this.disablePhysicsWhenPluggedIn = true;
        this.plugOffset = new THREE.Vector3();
        this.compatibleSocketLayers = [];
        this.connectedSocket = null;
        this.events = new EventTarget();
        this.cachedParent = null;
    }
    get plugPosition() { return this.gameObject.transform.transformPoint(this.plugOffset); }
    insert(socket) {
        if (!this.enabled)
            return;
        let detail = { socket: socket };
        this.events.dispatchEvent(new CustomEvent('OnInsert', { detail }));
        this.connectedSocket = socket;
        if (this.disablePhysicsWhenPluggedIn) {
            let rigidbody = this.gameObject.getComponent(Rigidbody);
            if (rigidbody != null)
                rigidbody.disableSimulation = true;
        }
        this.gameObject.transform.global_quaternion = MathUtility.lookRotation(socket.gameObject.transform.forward, socket.gameObject.transform.up);
        let vecBetween = socket.socketPosition.clone().sub(this.plugPosition);
        this.gameObject.transform.global_position = this.gameObject.transform.global_position.clone().add(vecBetween);
        ;
        if (this.childToSocket) {
            this.cachedParent = this.gameObject.parent;
            this.gameObject.setParent(socket.gameObject, true);
        }
        // notify the socket
        socket.receive(this);
    }
    remove() {
        if (!this.enabled)
            return;
        let socket = this.connectedSocket;
        this.connectedSocket = null;
        if (this.disablePhysicsWhenPluggedIn) {
            let rigidbody = this.gameObject.getComponent(Rigidbody);
            if (rigidbody != null)
                rigidbody.disableSimulation = false;
        }
        if (this.childToSocket) {
            this.gameObject.setParent(this.cachedParent);
        }
        socket.release();
        let detail = { socket: socket };
        this.events.dispatchEvent(new CustomEvent('OnRemove', { detail }));
    }
    isCompatible(socket) {
        for (var plugLayer of this.compatibleSocketLayers) {
            if (plugLayer == socket.socketLayerID)
                return true;
        }
    }
}
