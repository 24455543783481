"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeshRenderer = void 0;
const Component_1 = require("../../core/Component");
/**
 * Attach this component to a gameobject to allow rendering of geometry
 * with a specified material.
 */
class MeshRenderer extends Component_1.Component {
    /**
   * The mesh assigned to this mesh renderer
   */
    get mesh() { return this._mesh; }
    set mesh(value) {
        this._mesh = value;
    }
    /**
     * The material assigned to this mesh renderer
     */
    get material() { return this._material; }
    set material(value) {
        this._mesh.material = value;
        this._material = value;
    }
    /**
     * Should this mesh cast shadows?
     */
    get castShadows() { return this._mesh.castShadow; }
    set castShadows(value) {
        this._mesh.castShadow = value;
    }
    /**
    * Should this mesh receive shadows?
    */
    get receiveShadows() { return this._mesh.receiveShadow; }
    set receiveShadows(value) {
        this._mesh.receiveShadow = value;
    }
    /**
    * Is this mesh currently visible?
    */
    get visible() { return this._mesh.visible; }
    set visible(value) {
        this._mesh.visible = value;
    }
    /**
     * Construct this component with geometry and a material
     * @param geometry The geometry to render
     * @param material The material to use
     */
    constructor(mesh) {
        super();
        this._mesh = mesh;
        this._geometry = mesh.geometry;
        this._material = mesh.material;
    }
    /**
     * Initialise's this component with the passed in gameobject
     * @param gameObject The gameObject that owns this component
     */
    initialise(gameObject) {
        super.initialise(gameObject);
        // add as child of gameobject
        this.gameObject.threejs_object3d.add(this._mesh);
    }
    // INTERNAL
    _geometry = null;
    _material = null;
    _mesh = null;
}
exports.MeshRenderer = MeshRenderer;
