"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameObject = void 0;
const THREE = __importStar(require("three"));
const Application_1 = require("./Application");
const Transform_1 = require("./Transform");
class GameObject {
    // PUBLIC
    name = ""; // the name of the gameObject
    transform = null; // the transform component of this gameObject
    get parent() { return this._parent; } // the parent of this gameobject, null if no parent
    events = new EventTarget();
    constructor(name) {
        this.name = name;
        this.threejs_object3d = new THREE.Group();
        // start every gameobject with a transform component
        this.transform = this.addComponent(new Transform_1.Transform());
    }
    // Add a component to this gameobject
    addComponent(component) {
        component.initialise(this);
        component.awake();
        this.components.push(component);
        return component;
    }
    // Remove the passed in component from this gameobject
    removeComponent(component) {
        let index = this.components.indexOf(component);
        if (index < 0)
            return;
        this.components.splice(index, 1);
    }
    // Get the first component on this gameobject with the passed in type
    getComponent(type) {
        for (var i = 0; i < this.components.length; i++) {
            if (this.components[i] instanceof type)
                return this.components[i];
        }
        return null;
    }
    getComponentsInChildren(type, components) {
        let component = this.getComponent(type);
        if (component != null)
            components.push(component);
        for (let child of this.children) {
            child.getComponentsInChildren(type, components);
        }
    }
    /**
     * Get the first gameObject that is an immediate child of this gameobject with
     * the specified name.
     * @param name The name of the child gameObject
     */
    getChildByName(name) {
        for (let child of this.children) {
            if (child.name == name)
                return child;
        }
        return null;
    }
    setParent(gameObject, keep_global = true) {
        if (this == gameObject) {
            console.error("Unable to parent " + gameObject.name + "to itself.");
            return;
        }
        // if we want to set the parent to null (i.e back to the root scene)
        if (gameObject == null) {
            // if we had a parent, notify it of removal
            if (this._parent != null) {
                // notify of the removal of a child
                this._parent.notifyRemoveChild(this);
            }
            // reattach this gameobject to the scene
            this._parent = null;
            Application_1.Application.activeScene.addObject(this);
        }
        else //we want to set a parent to another gameObject
         {
            // if we had a parent
            if (this._parent != null) {
                // notify of the removal of a child
                this._parent.notifyRemoveChild(this);
            }
            else // notify the scene of removal
             {
                Application_1.Application.activeScene.removeObject(this);
            }
            if (keep_global)
                gameObject.threejs_object3d.attach(this.threejs_object3d);
            else
                gameObject.threejs_object3d.add(this.threejs_object3d);
            // set the parent
            this._parent = gameObject;
            gameObject.notifyAddChild(this);
        }
    }
    /**Add the passed in gameObject as child of this object */
    notifyAddChild(gameObject) {
        this.children.push(gameObject);
        let detail = { gameObject };
        this.events.dispatchEvent(new CustomEvent('OnChildAdded', { detail }));
    }
    notifyRemoveChild(gameObject) {
        this.children.splice(this.children.indexOf(gameObject), 1);
        let detail = { gameObject };
        this.events.dispatchEvent(new CustomEvent('OnChildRemoved', { detail }));
    }
    // Update all components
    update(dt) {
        for (var i = 0; i < this.components.length; i++) {
            this.components[i].update(dt);
        }
    }
    // Destroy this gameobject's resources and components
    destroy() {
        for (var i = 0; i < this.components.length; i++) {
            this.components[i].destroy();
        }
    }
    // INTERNAL
    threejs_object3d = null; // internal threejs object
    components = []; // list of components attached to this gameobject
    children = [];
    _parent = null;
}
exports.GameObject = GameObject;
