"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Physics = exports.RaycastHit = exports._Physics = void 0;
const THREE = __importStar(require("three"));
const Application_1 = require("../../core/Application");
const CANNON = __importStar(require("cannon-es"));
/**
 * Handles the physics
 */
class _Physics {
    /** @internal */
    constructor() {
    }
    initialise() {
        this.physics_world = new CANNON.World();
        this.physics_world.gravity.set(0.0, -9.82 * 2, 0.0);
        // start a physics update
        this.update();
        Application_1.Application.events.addEventListener('OnSceneLoaded', (event) => {
        });
    }
    ;
    update() {
        const elapsedTime = this.clock.getElapsedTime();
        const deltaTime = elapsedTime - this.prevElapsedTime;
        this.prevElapsedTime = elapsedTime;
        this.physics_world.step(1 / 60, deltaTime, 3);
        window.requestAnimationFrame(this.update.bind(this));
    }
    register(collider) {
        this.colliders.push(collider);
    }
    unregister(collider) {
    }
    addSpring(spring) {
        this.physics_world.addEventListener('postStep', (event) => {
            spring.applyForce();
        });
    }
    addBody(body) {
        let bodyExists = false;
        for (let trackedBody of this.physics_world.bodies) {
            if (trackedBody.id == body.id) {
                bodyExists = true;
                break;
            }
        }
        if (!bodyExists)
            this.physics_world.addBody(body);
    }
    removeBody(body) {
        let bodyExists = false;
        for (let trackedBody of this.physics_world.bodies) {
            if (trackedBody.id == body.id) {
                bodyExists = true;
                break;
            }
        }
        if (bodyExists)
            this.physics_world.removeBody(body);
    }
    raycastPhysicsFromCamera(mousePosition, camera, distance, hit_callback) {
        let ray = camera.screenToRay(mousePosition);
        let start = ray.origin.clone();
        let end = ray.origin.add(ray.direction.multiplyScalar(distance));
        return this.physics_world.raycastAll(new CANNON.Vec3(start.x, start.y, start.z), new CANNON.Vec3(end.x, end.y, end.z), { skipBackfaces: true }, (hit) => {
            // process the hit into a raycast hit
            for (let collider of this.colliders) {
                if (collider.rigidbody.containsBody(hit.body)) {
                    let raycastHit = new RaycastHit(collider);
                    raycastHit.point = new THREE.Vector3(hit.hitPointWorld.x, hit.hitPointWorld.y, hit.hitPointWorld.z);
                    raycastHit.normal = new THREE.Vector3(hit.hitNormalWorld.x, hit.hitNormalWorld.y, hit.hitNormalWorld.z);
                    hit_callback(raycastHit);
                }
            }
        });
    }
    raycastFromCamera(mousePosition, camera) {
        this.raycaster.setFromCamera(mousePosition, camera.threejs_camera);
        return this.cast();
    }
    raycast(origin, direction) {
        this.raycaster.set(origin, direction.normalize());
        return this.cast();
    }
    overlapSphere(position, radius) {
        // determine which colliders are nearby
        let nearbyColliders = [];
        for (let collider of this.colliders) {
            if (position.distanceTo(collider.gameObject.transform.global_position) < radius)
                nearbyColliders.push(collider);
        }
        return nearbyColliders;
    }
    cast() {
        let objects = [];
        for (let collider of this.colliders) {
            objects.push(collider.gameObject.threejs_object3d);
        }
        let intersects = this.raycaster.intersectObjects(objects);
        let hits = [];
        for (let intersect of intersects) {
            for (let collider of this.colliders) {
                if (collider.containsObject(intersect.object)) {
                    let hit = new RaycastHit(collider);
                    hit.point = intersect.point;
                    hit.normal = intersect.face.normal;
                    hits.push(new RaycastHit(collider));
                    break;
                }
            }
        }
        return hits;
    }
    clock = new THREE.Clock();
    prevElapsedTime = 0.0;
    colliders = [];
    raycaster = new THREE.Raycaster();
    physics_world = null;
}
exports._Physics = _Physics;
class RaycastHit {
    get collider() { return this._collider; }
    ;
    point = new THREE.Vector3();
    normal = new THREE.Vector3();
    constructor(collider) {
        this._collider = collider;
    }
    _collider = null;
}
exports.RaycastHit = RaycastHit;
/**
 * Singleton instance of the Physics class. Use this instance instead of creating a new Physics().
 */
exports.Physics = new _Physics();
