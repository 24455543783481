var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as THREE from "three";
import { gltfParser } from "wobble-engine/engine/systems/loaders/GLTFParser";
import { Materials } from "wobble-engine/engine/systems/loaders/MaterialDatabase";
import { Textures } from "wobble-engine/engine/systems/loaders/TextureDatabase";
import { Camera } from "wobble-engine/engine/core/Camera";
import { GameObject } from "wobble-engine/engine/core/GameObject";
import { Light } from "wobble-engine/engine/systems/lighting/Light";
import { MeshRenderer } from "wobble-engine/engine/systems/mesh/MeshRenderer";
import { Scene } from "wobble-engine/engine/core/Scene";
import { OrbitCamera } from "../OrbitCamera";
import { InteractionSystem } from "../systems/InteractionSystem";
import { Grabber } from "../actors/Grabber";
import { LightbulbAPI } from "../LightbulbAPI";
import { Switch } from "../actors/Switch";
import { Lightbulb } from "../actors/Lightbulb";
import { Rope } from "../actors/Rope";
import { EditorUtility } from "wobble-engine/engine/utility/EditorUtility";
import { WobbleShaderMaterial } from "wobble-engine/engine/shaders/wobble/WobbleShaderMaterial";
import { LightSocket } from "../actors/LightSocket";
export class LightbulbScene extends Scene {
    constructor() {
        super('lightbulb_scene');
        this.camera = null;
        this.switch = null;
        this.bulb = null;
        this.socket = null;
        this.attachPoint = null;
        this.interaction = null;
        this.api = null;
        this.rope = null;
        this.wobbleMaterial = null;
        this.grabber = null;
        this.identifier = "";
        this.ambientLight = null;
        // use query params
        // @ts-ignore
        let params = (new URL(document.location)).searchParams;
        let id = params.get("id");
        if (id != null) {
            this.identifier = id;
            console.log("[threejs] Received identifier " + this.identifier + " from url parameters.");
        }
        else {
            console.log("[threejs] No identifier found. Using default.");
            this.identifier = 'default';
        }
        // create database and write some data
        this.api = new LightbulbAPI();
        this.interaction = new InteractionSystem();
    }
    load(onSceneLoaded) {
        return __awaiter(this, void 0, void 0, function* () {
            Materials.createBasic("basic", new THREE.Color(0.75, 0.75, 0.75), null);
            var t1 = yield Textures.loadAsync('gradient_map', '/samples/gradients/3.jpg');
            t1.minFilter = THREE.NearestFilter;
            t1.magFilter = THREE.NearestFilter;
            t1.generateMipmaps = false;
            var t2 = yield Textures.loadAsync('gradient_1', '/samples/gradients/5_light.jpg');
            t2.minFilter = THREE.NearestFilter;
            t2.magFilter = THREE.NearestFilter;
            t2.generateMipmaps = false;
            // add a default toon material
            Materials.createToon("toon", t2);
            // call the on scene loaded event
            onSceneLoaded();
        });
    }
    awake() {
        super.awake();
        this.wobbleMaterial = new WobbleShaderMaterial(new THREE.Color(0.9, 0.5, 0.2));
        Materials.add("wobble", this.wobbleMaterial);
        this.createCamera();
        this.createLights();
        this.setupSceneObjects();
    }
    update(dt) {
        super.update(dt);
        if (this.socket != null && this.rope != null)
            this.rope.end = this.attachPoint.transform.global_position.sub(new THREE.Vector3(0, 0.2, 0));
    }
    setupSceneObjects() {
        return __awaiter(this, void 0, void 0, function* () {
            this.rope = this.createRope();
            this.rope.start = new THREE.Vector3(0, 20, 0);
            this.rope.length = 15.6;
            yield this.createLightswitch();
            yield this.createLightbulb();
            yield this.createLightsocket();
            this.bulb.events.addEventListener("OnInsert", (event) => {
                this.bulb.toggle(this.switch.IsOn);
            });
            this.bulb.events.addEventListener("OnUnplug", (event) => {
                this.bulb.toggle(false);
            });
            this.switch.events.addEventListener("OnToggle", (event) => {
                if (this.bulb.IsPluggedIn)
                    this.bulb.toggle(event.detail.isOn);
            });
            this.setupAPI();
            this.createUserInterface();
        });
    }
    createCamera() {
        // create camera
        this.camera = new GameObject("Camera");
        let cameraComponent = this.camera.addComponent(new Camera(window.innerWidth / window.innerHeight));
        this.camera.transform.position = new THREE.Vector3(0, -4, -5);
        this.camera.transform.rotation.set(0.0, 0, 0);
        var orbitCamera = this.camera.addComponent(new OrbitCamera());
        orbitCamera.vertical_offset = 1.0;
        orbitCamera.distance = 1.0;
        cameraComponent.orthographic = true;
        cameraComponent.orthographicSize = 40;
        this.grabber = this.camera.addComponent(new Grabber(this.interaction));
        EditorUtility.addInspectors(this.camera);
        this.addObject(this.camera);
    }
    createLights() {
        let ambientLight = new GameObject("Ambient Light");
        this.ambientLight = ambientLight.addComponent(new Light("ambient", new THREE.Color(0x999999), 0.5, false));
        this.addObject(ambientLight);
        let directionalLight = new GameObject("Directional Light");
        directionalLight.addComponent(new Light("directional", new THREE.Color(0xffffff), 0.5, true));
        directionalLight.transform.position.z = -2.0;
        directionalLight.transform.position.x = -5.0;
        directionalLight.transform.position.y = 5.0;
        this.addObject(directionalLight);
    }
    createLightswitch() {
        return __awaiter(this, void 0, void 0, function* () {
            // load the mesh into a gameobject and add it to the scene
            var gameObjects = yield gltfParser.parseAsync('/models/lightswitch.gltf');
            // add each object with default toon material
            for (let gameObject of gameObjects) {
                var meshRenderer = gameObject.getComponent(MeshRenderer);
                meshRenderer.receiveShadows = true;
                meshRenderer.castShadows = true;
                meshRenderer.material = new WobbleShaderMaterial(new THREE.Color(0xffffff));
                gameObject.transform.position.y = -3.0;
                this.addObject(gameObject);
            }
            // setup actual switch
            let toggle = this.getGameObjectByName("Toggle");
            this.switch = toggle.addComponent(new Switch());
            this.camera.getComponent(OrbitCamera).target = toggle;
        });
    }
    createLightbulb() {
        return __awaiter(this, void 0, void 0, function* () {
            // load the mesh into a gameobject and add it to the scene
            var gameObjects = yield gltfParser.parseAsync('/models/lightbulb.gltf');
            var gameObject = gameObjects[0];
            gameObject.transform.position.y = 4.0;
            gameObject.transform.rotation.set(-Math.PI, 0, 0);
            this.addObject(gameObject);
            // setup lightbulb
            let lightbulb = gameObject;
            this.bulb = lightbulb.addComponent(new Lightbulb());
            this.bulb.light = this.ambientLight;
            //this.pointLight.gameObject.setParent(this.bulb.gameObject);
            // // add creature to handle lightbulb connectivity
            // this.bulb.gameObject.addComponent(new LightbulbCreature(this.api, this.bulb));
        });
    }
    createLightsocket() {
        return __awaiter(this, void 0, void 0, function* () {
            var gameObjects = yield gltfParser.parseAsync('/models/socket.gltf');
            for (let go of gameObjects) {
                switch (go.name) {
                    case "Socket":
                        this.socket = go.addComponent(new LightSocket(this.grabber, this.rope));
                        this.socket.gameObject.transform.position.set(0, 5, 0);
                        this.socket.gameObject.transform.rotation.set(-Math.PI, 0, 0);
                        break;
                }
                this.addObject(go);
            }
            this.attachPoint = this.socket.gameObject.getChildByName("AttachPoint");
        });
    }
    createRope() {
        const rope = new GameObject("Rope");
        let ropeComponent = rope.addComponent(new Rope());
        this.addObject(rope);
        return ropeComponent;
    }
    // setup the api communication
    setupAPI() {
        this.api.read(`instances/${this.identifier}/properties/on/value`, (value) => this.switch.IsOn = value);
        this.api.listen(`instances/${this.identifier}/properties/on/value`, (snapshot) => this.switch.IsOn = snapshot.val());
        this.api.read(`instances/${this.identifier}/properties/brightness/value`, (value) => this.bulb.Brightness = value * this.bulb.MaxBrightness);
        this.api.listen(`instances/${this.identifier}/properties/brightness/value`, (snapshot) => this.bulb.Brightness = snapshot.val() * this.bulb.MaxBrightness);
        this.api.read(`instances/${this.identifier}/properties/color/value`, (value) => this.bulb.Color = value);
        this.api.listen(`instances/${this.identifier}/properties/color/value`, (snapshot) => this.bulb.Color = snapshot.val());
        // check for switch clicks, and write to api immediately
        this.interaction.events.addEventListener("OnGrab", (event) => {
            var info = event.detail.interaction;
            if (info.target != null && info.target.name == "Toggle") {
                // toggle switch
                this.switch.IsOn = !this.switch.IsOn;
                this.api.write(`instances/${this.identifier}/properties/on/value`, this.switch.IsOn);
            }
        });
    }
    createUserInterface() {
        // const canvas = new GameObject("UI Canvas");
        // this.addObject(canvas);
        //
        // const image = new GameObject("Image");
        // var imageComponent = image.addComponent(new CSSUIImage('./textures/phone-frame.png', '.phone' )) as CSSUIImage;
        //
        // image.setParent(canvas);
        // EditorUtility.addInspectors(image);
    }
}
