"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoxCollider = void 0;
const Collider_1 = require("./Collider");
const THREE = __importStar(require("three"));
class BoxCollider extends Collider_1.Collider {
    get local_size() { return this.size; }
    get world_size() { return this.gameObject.transform.global_scale.clone().multiply(this.size.clone()); }
    get local_center() { return this.center; }
    get world_center() { return this.gameObject.transform.transformPoint(this.center); }
    events = new EventTarget();
    /**
     * Construct a new box collider with a specific size and center point.
     * The size and center is local to the gameObject transform.
    */
    constructor(size, center) {
        super(center);
        this.size = size;
    }
    ;
    awake() {
        super.awake();
        this.type = Collider_1.ColliderType.BOX;
    }
    size = new THREE.Vector3();
}
exports.BoxCollider = BoxCollider;
