"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Light = void 0;
const THREE = __importStar(require("three"));
const Component_1 = require("../../core/Component");
/**
 * Base component used to handle lights in the scene.
 */
class Light extends Component_1.Component {
    /**
     * The current color of the light
     * @constructor
     */
    get Color() { return this.threejs_light.color; }
    set Color(value) {
        this.threejs_light.color = value;
    }
    /**
     * The current intensity of the light
     * @constructor
     */
    get Intensity() { return this.threejs_light.intensity; }
    set Intensity(value) {
        this.threejs_light.intensity = value;
    }
    constructor(type, color, intensity, castShadows) {
        super();
        // create a light based on the passed in type
        // for now just ambient, point or directional
        switch (type) {
            case "ambient":
                this.threejs_light = new THREE.AmbientLight(color, intensity);
                break;
            case "directional":
                this.threejs_light = new THREE.DirectionalLight(color, intensity);
                break;
            case "point":
                this.threejs_light = new THREE.PointLight(color, intensity);
                break;
        }
        this.threejs_light.castShadow = castShadows;
        if (castShadows) {
            this.threejs_light.shadow.mapSize.width = 1024;
            this.threejs_light.shadow.mapSize.height = 1024;
            this.threejs_light.shadow.bias = 0.00;
            if (type === 'directional') {
                var side = 15;
                this.threejs_light.shadow.camera.left = side;
                this.threejs_light.shadow.camera.bottom = -side;
                this.threejs_light.shadow.camera.left = side;
                this.threejs_light.shadow.camera.right = -side;
            }
        }
        this.type = type;
    }
    initialise(gameObject) {
        super.initialise(gameObject);
        this.gameObject.threejs_object3d.add(this.threejs_light);
    }
    awake() {
    }
    // INTERNAL
    type = "unknown";
    threejs_light = null;
}
exports.Light = Light;
